import InputMask from "react-input-mask";
import {
  ChangeEvent,
  useState,
  FormEvent,
  useContext,
  Fragment,
  useEffect,
} from "react";
import RemoveFileIcon from "../../assets/icons/RemoveFileIcon";
import { GlobalContext } from "../../context/globalContext";
import { formatAsciiCharacters } from "../../utils/formatAsciiCharacters";
import { ButtonPrimary } from "@telefonica/mistica";
import { api } from "../../lib/api";
import AgilizePaperIcon from "../../assets/icons/AgilizePaperIcon";
import SuccessMessage from "../SuccessMessage";
import formatHourToFile from "../../utils/formatHourToFile";
import * as S from "./styles";
import handleResponsiveScroll from "../../mocks/handleResponsiveScroll";
import AdvantageIcon from "../../assets/icons/AdvantageIcon";

import CryptoJS from "crypto-js";

interface IFormErrors {
  fullName: string;
  cellphone: string;
  email: string;
  message: string;
  contactForms: string;
  terms: string;
  file: string;
}

const resultFormContactOptions = ["E-mail", "Telefone", "WhatsApp"];

const defaultFormFields = {
  fullName: "",
  cellphone: "",
  email: "",
  message: "",
  terms: true as boolean,
  contactForms: resultFormContactOptions,
};

const SimulationResult = () => {
  const {
    resultInformations,
    setSelectedCity,
    setMonthlyValue,
    setSelectedState,
    setShowSimulationResult,
    setResultInformations,
    setPhoneNumber,
  } = useContext(GlobalContext);

  const [formFields, setFormFields] = useState(defaultFormFields);

  const [lightInvoiceFile, setLightInvoiceFile] = useState<File | null>();
  const [apiError, setApiError] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [loadingFormApi, setLoadingFormApi] = useState(false);
  const [formErrors, setFormErrors] = useState({} as IFormErrors);

  const disableSendButton = loadingFormApi;

  useEffect(() => {
    handleResponsiveScroll(".simulationContainer", { desk: -560, mob: -80 });
  }, []);

  function handleGetInvoiceFile(e: ChangeEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement;
    const files = target.files;

    if (files) {
      setLightInvoiceFile(files[0]);
    }
  }

  function handleChangeInputFieldValue(field: string, value: string) {
    setFormFields((oldValue) => {
      return {
        ...oldValue,
        [field]: value,
      };
    });
  }

  function handleWithCheckboxFields(value: string) {
    let formCheckbox: string[] = [];

    const checkIfValueAlreadyExists = formFields.contactForms.some(
      (check) => check === value
    );

    if (checkIfValueAlreadyExists) {
      formCheckbox = formFields.contactForms.filter(
        (values) => values !== value
      );
    } else {
      formCheckbox = [...formFields.contactForms, value];
    }

    setFormFields((oldValue) => {
      return {
        ...oldValue,
        contactForms: formCheckbox,
      };
    });
  }

  function checkErrorsOnForm() {
    const errors = {} as IFormErrors;

    const { fullName, email, cellphone, contactForms, terms } = formFields;

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const cellPhoneRegex = /^\(\d{2}\) \d \d{4} \d{4}$/;

    if (!fullName) {
      errors.fullName = "Nome obrigatório";
    }

    if (!email) {
      errors.email = "E-mail obrigatório";
    } else if (!email.match(emailRegex)) {
      errors.email = "E-mail inválido";
    }

    if (!cellphone) {
      errors.cellphone = "Telefone obrigatório";
    } else if (!cellphone.match(cellPhoneRegex)) {
      errors.cellphone = "Telefone inválido";
    }

    if (!contactForms.length) {
      errors.contactForms = "Selecione uma das opções";
    }

    if (terms === false) {
      errors.terms = "É necessário aceitar os termos para prosseguir";
    }

    setFormErrors(errors);
  }

  async function submitForm() {
    let fileName = "";

    const { dateString, hourString } = formatHourToFile();

    const fileExtension = lightInvoiceFile?.name.replace(/.*\.(.*)/, "$1");
    const formatCnpj = "00.000.000/0001-00".replace(/\D/g, "");

    if (lightInvoiceFile) {
      fileName = `faturas/${dateString}/${formatCnpj}-${formFields.email}-${hourString}.${fileExtension}`;
    }

    let url = "";

    const formatName = formatAsciiCharacters(formFields.fullName);
    const formatCity = formatAsciiCharacters(
      resultInformations?.city as string
    );
    const formatMessage = formatAsciiCharacters(formFields.message);

    try {
      if (lightInvoiceFile) {
        const fileRes = await api.get(
          import.meta.env.VITE_APP_API_UPLOAD_FILE,
          {
            params: {
              path: "",
              origem: "migracoes",
              lead_origem: "migracoes-vivo",
              nome_arquivo: fileName,
              tipo_conteudo: lightInvoiceFile?.type,
              nome_cp: formatName,
              email_cp: formFields.email,
              telefone_cp: formFields.cellphone,
              mensagem_cp: formatMessage,
              valor_aproximado_fatura: resultInformations?.monthlyValue,
              municipio_ibge_id: resultInformations?.cityId,
              municipio: formatCity,
              contato: formFields.contactForms.toString(),
              desconto_visualizado: `${resultInformations?.percentage}%`,
              termos_uso: true,
            },
          }
        );

        if (fileRes.status === 200) {
          url = fileRes.data.dados.url_upload;

          const sendData = await api.put(url, lightInvoiceFile, {
            headers: {
              "Content-Type": lightInvoiceFile.type,
            },
          });

          if (sendData.status === 200) {
            setShowSuccessMessage(true);
          }
        }
      } else {
        const formatCellphone = formFields.cellphone
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll(" ", "");

        const dataResOnly = await api.post(
          import.meta.env.VITE_APP_API_WITHOUT_INVOICE,
          {
            nome: formatName,
            telefone: formatCellphone,
            email: formFields.email,
            mensagem: formatMessage,
            origem: "migracoes",
            lead_origem: "migracoes-vivo",
            valor_aproximado_fatura: resultInformations?.monthlyValue,
            municipio_ibge_id: resultInformations?.cityId,
            municipio: formatCity,
            contato: formFields.contactForms.map((c) => c.toLowerCase()),
            desconto_visualizado: `${resultInformations?.percentage}%`,
            termos_uso: true,
          }
        );

        if (dataResOnly.data.success) {
          setShowSuccessMessage(true);
        } else {
          throw new Error(dataResOnly.data.mensagem_usuario);
        }
      }
    } catch (e) {
      console.log(e);

      setApiError("Ocorreu um erro ao enviar as informações...");
    } finally {
      const tipoLead = localStorage.getItem("tipoLead");
      const consumo = +(localStorage.getItem("consumo") || 0);

      const emailCriptografado = CryptoJS.SHA256(formFields.email).toString();
      const telCriptografado = CryptoJS.SHA256(formFields.cellphone).toString();

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "secondForm_conversion",
        tipoLead: tipoLead,
        consumo: consumo,
        email: emailCriptografado,
        tel: telCriptografado,
      });

      setLoadingFormApi(false);
    }
  }

  async function handleSubmitForm(e: FormEvent) {
    e.preventDefault();

    checkErrorsOnForm();

    setLoadingFormApi(true);
  }

  function handleRedoSimulation() {
    setLoadingFormApi(false);
    setShowSuccessMessage(false);
    setShowSimulationResult(false);
    setResultInformations(null);
    setSelectedCity(null);
    setLightInvoiceFile(null);
    setFormFields(defaultFormFields);
    setMonthlyValue("");
    setSelectedState("");

    setTimeout(() => {
      handleResponsiveScroll(".economySimulation", { desk: -590, mob: -340 });
    }, 300);
  }

  useEffect(() => {
    const doesFormHasErrors = Object.keys(formErrors);

    if (!doesFormHasErrors.length && loadingFormApi) {
      submitForm();
    } else {
      setLoadingFormApi(false);
    }
  }, [formErrors]);

  return (
    <Fragment>
      {!showSuccessMessage ? (
        <S.SimulationRoot>
          <S.SimulationWrapper className="simulationContainer">
            <S.InfoCol>
              <S.DiscountHeader>
                Pronto para economizar com a GUD Energia?
              </S.DiscountHeader>

              <S.DiscountValue>
                {resultInformations?.percentage ?? "0"}% ao mês!
              </S.DiscountValue>

              <p>Em 5 anos, isso pode representar uma economia de:</p>

              <S.DiscountValueIn3Years>
                {resultInformations?.economy ?? "R$ 00,00"}
              </S.DiscountValueIn3Years>

              <S.DiscountAdvantageTextRow>
                <AdvantageIcon />
                <p>
                  Sem mudar seu padrão de consumo e sem necessidade de
                  investimento!
                </p>
              </S.DiscountAdvantageTextRow>

              <S.SmallText>
                Essa simulação de desconto possui efeitos indicativos e não deve
                ser considerada como uma proposta comercial.{" "}
                <strong>
                  Para saber o desconto exato, envie uma fatura recente de
                  energia elétrica e aguarde o contato de um consultor.
                </strong>
              </S.SmallText>

              <S.RedoSimulation $hideOnMobile={true}>
                <button onClick={() => handleRedoSimulation()} type="button">
                  Simular novamente
                </button>
              </S.RedoSimulation>
            </S.InfoCol>

            <S.SimulationCol>
              <S.SimulationFormWrapper>
                <S.FormTitle>
                  <strong>Deixe seus dados que entraremos em contato:</strong>
                </S.FormTitle>
                <S.SimulationResultForm onSubmit={handleSubmitForm}>
                  <S.InputSection>
                    <label>
                      <input
                        onChange={({ target }) =>
                          handleChangeInputFieldValue("fullName", target.value)
                        }
                        type="text"
                      />
                      <S.FloatingText
                        $willFloat={Boolean(formFields.fullName)}
                        className={formErrors.fullName ? "errorMsg" : ""}
                      >
                        Nome e sobrenome
                      </S.FloatingText>
                      {formErrors.fullName && (
                        <S.FieldErrorMsg>{formErrors.fullName}</S.FieldErrorMsg>
                      )}
                    </label>
                    <label>
                      <InputMask
                        onChange={({ target }) => {
                          handleChangeInputFieldValue(
                            "cellphone",
                            target.value
                          );
                          setPhoneNumber(target.value);
                        }}
                        type="text"
                        mask="(99) 9 9999 9999"
                      />
                      <S.FloatingText
                        $willFloat={Boolean(formFields.cellphone)}
                        className={formErrors.cellphone ? "errorMsg" : ""}
                      >
                        Telefone de contato
                      </S.FloatingText>
                      {formErrors.cellphone && (
                        <S.FieldErrorMsg>
                          {formErrors.cellphone}
                        </S.FieldErrorMsg>
                      )}
                    </label>
                  </S.InputSection>

                  <S.InputSection>
                    <label>
                      <input
                        onChange={({ target }) =>
                          handleChangeInputFieldValue("email", target.value)
                        }
                        type="text"
                      />
                      <S.FloatingText
                        $willFloat={Boolean(formFields.email)}
                        className={formErrors.email ? "errorMsg" : ""}
                      >
                        E-mail de contato
                      </S.FloatingText>
                      {formErrors.email && (
                        <S.FieldErrorMsg>{formErrors.email}</S.FieldErrorMsg>
                      )}
                    </label>
                  </S.InputSection>

                  <S.InputSection>
                    <label>
                      <input
                        onChange={({ target }) =>
                          handleChangeInputFieldValue("message", target.value)
                        }
                        type="text"
                      />
                      <S.FloatingText
                        $willFloat={Boolean(formFields.message)}
                        className={formErrors.message ? "errorMsg" : ""}
                      >
                        Mensagem (opcional)
                      </S.FloatingText>
                      {formErrors.message && (
                        <S.FieldErrorMsg>{formErrors.message}</S.FieldErrorMsg>
                      )}
                    </label>
                  </S.InputSection>

                  <S.CheckboxGroupWrapper>
                    <S.CheckboxSection>
                      <S.CheckboxGroupTitle>
                        Como podemos falar com você?
                      </S.CheckboxGroupTitle>

                      <S.CheckboxArea>
                        {resultFormContactOptions.map((checkbox) => {
                          return (
                            <label
                              key={checkbox}
                              className="control control_checkbox"
                            >
                              <input
                                onChange={({ target }) =>
                                  handleWithCheckboxFields(target.value)
                                }
                                defaultChecked={formFields.contactForms.includes(
                                  checkbox
                                )}
                                value={checkbox}
                                type="checkbox"
                              />
                              <div className="control_indicator" />
                              {checkbox}
                            </label>
                          );
                        })}
                      </S.CheckboxArea>
                    </S.CheckboxSection>
                    <S.FieldErrorMsg>{formErrors.contactForms}</S.FieldErrorMsg>
                  </S.CheckboxGroupWrapper>

                  <S.AgilizeWrapper>
                    <S.AgilizeTitle>
                      <p className="highlighted">
                        Quer agilizar seu atendimento?
                      </p>
                      <p>Anexe uma conta de luz recente (últimos 3 meses)</p>
                    </S.AgilizeTitle>

                    <S.SendInvoice>
                      <AgilizePaperIcon />

                      <S.InvoiceFileInputWrapper>
                        <span>
                          Conta de Luz:
                          <label
                            style={
                              lightInvoiceFile
                                ? { textDecoration: "none" }
                                : { textDecoration: "underline" }
                            }
                          >
                            {lightInvoiceFile
                              ? lightInvoiceFile.name
                              : "Selecionar arquivo"}
                            <input
                              type="file"
                              disabled={!!lightInvoiceFile}
                              onChange={(e) => handleGetInvoiceFile(e)}
                              accept=".pdf, image/*"
                              onClick={(e) => {
                                const target = e.target as HTMLInputElement;

                                target.value = "";
                              }}
                            />
                          </label>
                        </span>
                        {lightInvoiceFile && lightInvoiceFile.name && (
                          <button
                            onClick={() => {
                              setLightInvoiceFile(null);
                            }}
                            type="button"
                          >
                            <RemoveFileIcon />
                          </button>
                        )}
                      </S.InvoiceFileInputWrapper>
                    </S.SendInvoice>
                    {apiError && <S.FileError>{apiError}</S.FileError>}
                    <S.FileError>
                      {formErrors.file && formErrors.file}
                    </S.FileError>
                  </S.AgilizeWrapper>
                  <S.InvoiceFormFooter>
                    <S.CheckboxArea>
                      <label className="control control_checkbox">
                        <input
                          defaultChecked={true}
                          type="checkbox"
                          onChange={({ target }) =>
                            // @ts-ignore
                            handleChangeInputFieldValue("terms", target.checked)
                          }
                          // @ts-ignore
                          value={formFields.terms}
                        />
                        <p>
                          Ao enviar você autoriza a GUD e seus parceiros a
                          entrar em contato com você via e-mail, telefone ou
                          WhatsApp e declara ter lido a{" "}
                          <a target="__blank" href="/normas-de-privacidade">
                            Norma de Privacidade
                          </a>
                          .
                        </p>
                        <div className="control_indicator" />
                      </label>
                    </S.CheckboxArea>
                  </S.InvoiceFormFooter>
                  {formErrors.terms && (
                    <S.FieldErrorMsg>{formErrors.terms}</S.FieldErrorMsg>
                  )}
                  <S.SendInvoiceButton
                    id="btnEnviar"
                    $formLoading={loadingFormApi}
                  >
                    <ButtonPrimary
                      className={`simulateButton ${lightInvoiceFile ? 'secondForm_conversion_with_invoice' : 'secondForm_conversion'}`}
                      loadingText="Enviando..."
                      submit
                      disabled={disableSendButton}
                      showSpinner={loadingFormApi}
                    >
                      {lightInvoiceFile ? "Enviar" : "Enviar sem fatura"}
                    </ButtonPrimary>
                  </S.SendInvoiceButton>
                </S.SimulationResultForm>
              </S.SimulationFormWrapper>

              <S.RedoSimulation $hideOnMobile={false}>
                <button onClick={() => handleRedoSimulation()} type="button">
                  Simular novamente
                </button>
              </S.RedoSimulation>
            </S.SimulationCol>
          </S.SimulationWrapper>
        </S.SimulationRoot>
      ) : (
        <SuccessMessage ineligible={false} />
      )}

      {/*  <ResultSmsMessage /> */}
    </Fragment>
  );
};

export default SimulationResult;
