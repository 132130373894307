const InfoSentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="108"
      height="82"
      viewBox="0 0 108 82"
      fill="none"
      style={{ width: "100%", height: "100%" }}
    >
      <path
        d="M88.3286 65.625H67.8571V57.375H88.3286H90.722L89.04 55.6722L81.6397 48.1806L87.4286 42.4118L106.583 61.5L87.4286 80.5882L81.6397 74.8195L89.04 67.3278L90.722 65.625H88.3286ZM3.72927 3.72244L3.73048 3.72123C5.55669 1.89826 7.72364 1 10.2857 1H77.1429C79.705 1 81.874 1.89832 83.7038 3.72183C85.5298 5.54147 86.4286 7.69945 86.4286 10.25V29.75H78.1429V17.425V15.5129L76.5724 16.6037L43.2073 39.7774L10.8659 16.7387L9.28571 15.613V17.5531V61.5V62.5H10.2857H55.5714V70.75H10.2857C7.72309 70.75 5.55596 69.853 3.72988 68.0333C1.90011 66.2099 1 64.05 1 61.5V10.25C1 7.7 1.90005 5.5422 3.72927 3.72244ZM17.7429 9.25H14.6238L17.1614 11.0636L42.6185 29.2573L43.1914 29.6667L43.77 29.2654L69.9985 11.0717L72.6247 9.25H69.4286H17.7429Z"
        fill="currentColor"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};

export default InfoSentIcon;
