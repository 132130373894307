import { useContext, useState, useEffect, useMemo, Fragment } from "react";
import { GlobalContext } from "../../context/globalContext";
import * as S from "./styles";
import { api } from "../../lib/api";

import OutcomeModal from "../OutcomeModal";

import AdvantageIcon from "../../assets/icons/AdvantageIcon";
import ArrowMenuIcon from "../../assets/icons/ArrowMenuIcon";

import Question1 from "../../assets/img/question1.png";
import Question2 from "../../assets/img/question2.png";
import Question3 from "../../assets/img/question3.png";


const QuestionsModal = () => {
    const questions = useMemo(() => [
        {
            pergunta: "Sua empresa é do Grupo A?", 
            img: Question1, 
            text: "Grupo A é como são classificados os consumidores que estão conectados em média ou alta tensão, acima de 2,3 kV. São empresas que possuem um alto consumo de energia elétrica e contratam uma demanda de fornecimento junto a distribuidora. Caso não saiba, consulte esta informação na fatura da sua distribuidora."
        },
        {
            pergunta: "Sua empresa já está no Mercado Livre de Energia?", 
            img: Question2, 
            text: "O Mercado Livre de Energia é um ambiente onde consumidores e fornecedores negociam diretamente a compra e venda de energia elétrica. Desta forma, é possível obter mais flexibilidade e competitividade nas transações de energia."
        },
        {
            pergunta: "Sua empresa possui painéis fotovoltaicos?", 
            img: Question3, 
            text: "Um painel fotovoltaico é um dispositivo que converte a luz solar em eletricidade, utilizando células fotovoltaicas para gerar energia elétrica a partir da luz do sol."
        }
    ], []);

    const {
        resultInformations,
        phoneNumber,
    } = useContext(GlobalContext);

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [answers, setAnswers] = useState<any>({});
    const [selectedAnswer, setSelectedAnswer] = useState<string | null>("S");
    const [showOutcomeModal, setShowOutcomeModal] = useState(false);
    const [rightFirstAnswer, setRightFirstAnswer] = useState(true);
    const [rightSecondAnswer, setRightSecondAnswer] = useState(true);
    const [rightThirdAnswer, setRightThirdAnswer] = useState(true);
    // const [apiError, setApiError] = useState("");

    const handleAnswer = (answer: string) => {
        setSelectedAnswer(answer);
    };

    const handleNextQuestion = () => {
        setAnswers({
            ...answers,
            [currentQuestionIndex]: selectedAnswer,
        });
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    useEffect(() => {
        async function saveAnswers() {
            try {
                const sendAnswers = await api.post(
                    import.meta.env.VITE_APP_SAVE_QUESTION,
                    {
                        grupo_a: answers[0],
                        mercado_energia: answers[1],
                        paineis_fotvol: answers[2],
                        telefone: phoneNumber,
                    }
                );

                if (sendAnswers.data.success) {
                    console.log("Respostas enviadas com sucesso!");
                } else {
                    throw new Error(sendAnswers.data.mensagem_usuario);
                }
            } catch (e) {
                console.log(e);
                // setApiError("Ocorreu um erro ao enviar as informações...");
            }
        }

        if (Object.keys(answers).length > 0) {
            if (answers[0] !== "S") {
                console.log("Usuário não elegível");
                setRightFirstAnswer(false);
                setShowOutcomeModal(true);
            } else if (answers[0] === "S" && answers[1] === "S") {
                console.log("Usuário não elegível");
                setRightSecondAnswer(false);
                setShowOutcomeModal(true);
            } else if (answers[0] === "S" && answers[1] === "N" && answers[2] === "S") {
                console.log("Usuário não elegível");
                setRightThirdAnswer(false);
                setShowOutcomeModal(true);
            } else if (answers[0] === "S" && answers[1] === "N" && answers[2] === "N") {
                console.log("Usuário elegível");
                setShowOutcomeModal(true);
            }

            saveAnswers();
        }
    }, [answers, questions, phoneNumber]);

    return (
        <Fragment>
            {!showOutcomeModal ? (
            <S.QuestionRoot>
                <S.QuestionWrapper>
                    <S.InfoCol>
                        <S.DiscountHeader>
                            Pronto para economizar com a GUD Energia?
                        </S.DiscountHeader>

                        <S.DiscountValue>
                            {resultInformations?.percentage ?? "0"}% ao mês!
                        </S.DiscountValue>

                        <p>Em 5 anos, isso pode representar uma economia de:</p>

                        <S.DiscountValueIn3Years>
                            {resultInformations?.economy ?? "R$ 00,00"}
                        </S.DiscountValueIn3Years>

                        <S.DiscountAdvantageTextRow>
                            <AdvantageIcon />
                            <p>
                                Sem mudar seu padrão de consumo e sem necessidade de
                                investimento!
                            </p>
                        </S.DiscountAdvantageTextRow>

                        <S.SmallText>
                            Essa simulação de desconto possui efeitos indicativos e não deve
                            ser considerada como uma proposta comercial.{" "}
                            <strong>
                                Para saber o desconto exato, envie uma fatura recente de
                                energia elétrica e aguarde o contato de um consultor.
                            </strong>
                        </S.SmallText>
                    </S.InfoCol>
                    <S.QuestionFormCol>
                        <S.QuestionFormWrapper>
                            <S.ModalContent>
                                <p>
                                    Cadastro realizado com sucesso! <br></br> 
                                    <strong>Confirme as informações abaixo para agilizar o seu atendimento:</strong>
                                </p>
                                <S.SubText>
                                    <br></br>(esta etapa é opcional mas pode tornar o contato bem mais rápido)
                                </S.SubText>
                                <S.QuestionBar>
                                    <S.Ellipse>1</S.Ellipse>
                                    <p 
                                        style={{ fontWeight: currentQuestionIndex === 0 ? "bold" : "", color: "#b0049c" }}
                                        onClick={() => {
                                            setCurrentQuestionIndex(0);
                                            setAnswers({});
                                        }}
                                    >
                                        Grupo A
                                    </p>
                                    <ArrowMenuIcon color="#b0049c" />
                                    <S.Ellipse>2</S.Ellipse>
                                    <p 
                                        style={{ 
                                            fontWeight: currentQuestionIndex === 1 ? "bold" : "", 
                                            color: currentQuestionIndex !== 0 ? "#b0049c" : ""
                                        }}
                                        onClick={() => {
                                            if (currentQuestionIndex > 1) {
                                                setCurrentQuestionIndex(1);
                                            }
                                        }}
                                    >
                                        Mercado Livre
                                    </p>
                                    <ArrowMenuIcon color={currentQuestionIndex !== 0 ? "#b0049c" : ""} />
                                    <S.Ellipse>3</S.Ellipse>
                                    <p 
                                        style={{
                                            fontWeight: currentQuestionIndex === 2 ? "bold" : "",
                                            color: currentQuestionIndex === 2 ? "#b0049c" : ""
                                        }}
                                        onClick={() => {
                                            if (currentQuestionIndex > 2) {
                                                setCurrentQuestionIndex(2);
                                            }
                                        }}
                                    >
                                        Painéis Fotovoltaicos
                                    </p>
                                </S.QuestionBar>
                                <h2>{questions[currentQuestionIndex].pergunta}</h2>
                                <S.ImageContainer>
                                    <S.CenteredImage src={questions[currentQuestionIndex].img}/>
                                </S.ImageContainer>
                                <p>
                                    {questions[currentQuestionIndex].text}
                                </p>
                                <S.ButtonsContainer>
                                    <S.YesOrNoButtonsContainer>
                                        <S.QuestionsButtonA selected={selectedAnswer === "S"}>
                                            <button onClick={() => handleAnswer("S")}>Sim</button>
                                        </S.QuestionsButtonA>
                                        <S.QuestionsButtonA selected={selectedAnswer === "N"}>
                                            <button onClick={() => handleAnswer("N")}>Não</button>
                                        </S.QuestionsButtonA>
                                    </S.YesOrNoButtonsContainer>
                                    <S.QuestionsButtonB>
                                        <button onClick={handleNextQuestion}>
                                            {currentQuestionIndex < questions.length - 1 ? "Próxima pergunta" : "Concluir"}
                                            {currentQuestionIndex < questions.length - 1 ? <ArrowMenuIcon color="#fff" /> : ""}
                                        </button>
                                    </S.QuestionsButtonB>
                                </S.ButtonsContainer>
                            </S.ModalContent>
                        </S.QuestionFormWrapper>
                    </S.QuestionFormCol>
                </S.QuestionWrapper>
            </S.QuestionRoot>
            ) : (
                <OutcomeModal rightFirstAnswer={rightFirstAnswer} rightSecondAnswer={rightSecondAnswer} rightThirdAnswer={rightThirdAnswer} />
            )}
        </Fragment>
    );
};

export default QuestionsModal;
