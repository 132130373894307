const CloseMenuIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M16.8408 17.8528C17.1239 18.0683 17.5298 18.0468 17.7883 17.7882C18.0704 17.5061 18.0704 17.0488 17.7883 16.7667L13.0213 11.9997L17.788 7.23305L17.8526 7.15908C18.0681 6.87597 18.0465 6.47013 17.788 6.21156C17.5059 5.92948 17.0485 5.92948 16.7665 6.21156L11.9998 10.9782L7.23294 6.21131L7.15898 6.14667C6.87586 5.9312 6.47002 5.95274 6.21145 6.21131C5.92938 6.49339 5.92938 6.95073 6.21145 7.2328L10.9783 11.9997L6.21107 16.767L6.14643 16.8409C5.93095 17.124 5.9525 17.5299 6.21107 17.7884C6.49315 18.0705 6.95048 18.0705 7.23256 17.7884L11.9998 13.0212L16.7668 17.7882L16.8408 17.8528Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CloseMenuIcon
