export default function formatHourToFile() {
  const now = new Date().toLocaleString();

  const [date, hours] = now.split(/,| /);

  const [hour, minute, second] = hours.split(":");
  const [day, month, year] = date.split("/");

  const dateString = `${year}-${month}-${day}`.trim();
  const hourString = `${hour}h${minute}m${second}s`.trim();

  return { hourString, dateString };
}
