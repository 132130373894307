export const headerLinks = [
  {
    title: "Início",
    scrollPos: 0,
    anchorClass: ".start",
    anchor: 0,
    anchorMob: 0,
  },

  {
    title: "Sobre Nós",
    scrollPos: 17,
    anchorClass: ".aboutDesk",
    anchor: -460,
    anchorMob: -440,
  },

  {
    title: "Conheça as vantagens",
    scrollPos: 30,
    anchorClass: ".AdvantageDesk",
    anchor: -300,
    anchorMob: -800,
  },

  {
    title: "Imprensa",
    scrollPos: 56,
    anchorClass: ".MediaDesk",
    anchor: -300,
    anchorMob: -400,
  },

  {
    title: "Perguntas frequentes",
    scrollPos: 67,
    anchorClass: ".faq",
    anchor: -80,
    anchorMob: -80,
  },
];
