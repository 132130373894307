import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "../../context/globalContext";
import GoTopButton from "../GoTopButton";
import AboutComponent from "./AboutComponent";
import HomeFaq from "./HomeFaq";
import { HomeContainer } from "./styles";
import EconomyCalculation from "./EconomyCalculation";
import SimulationResult from "../SimulationResult";
import AdvantageComponent from "./AdvantageComponent";
import MediaComponent from "./MediaComponent";

const Home = () => {
  const { showSimulationResult } = useContext(GlobalContext);

  const location = useLocation();

  useEffect(() => {
    if (
      location.state &&
      (location.state as { anchorElement: string; scrollValue: number })
        .anchorElement
    ) {
      const { anchorElement, scrollValue } = location.state as {
        anchorElement: string;
        scrollValue: number;
      };

      const element = document.querySelector(anchorElement) as HTMLElement;

      if (element) {
        const scrollVal =
          element.getBoundingClientRect().top + window.scrollY + scrollValue;

        window.scrollTo({ top: scrollVal, behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <HomeContainer>
      <>
        {!showSimulationResult ? <EconomyCalculation /> : <SimulationResult />}
        <AboutComponent />
        <AdvantageComponent />
        <MediaComponent />
        <HomeFaq />
        <GoTopButton />
      </>
    </HomeContainer>
  );
};

export default Home;
