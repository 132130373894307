export default function handleResponsiveScroll(
  className: string,
  offSet: { mob: number; desk: number }
) {
  const getComponent = document.querySelector(className) as HTMLElement

  const yOffset = window.innerWidth <= 768 ? offSet.mob : offSet.desk

  if (getComponent) {
    const scrollVal =
      getComponent.getBoundingClientRect().top + window.scrollY + yOffset

    window.scrollTo({ top: scrollVal, behavior: "smooth" })
  }
}
