export default function currencyFormatter(value: string) {
  const valueToFormat = Number(value)

  const formattedValue = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(valueToFormat)

  return formattedValue
}
