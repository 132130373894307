export function formatAsciiCharacters(string: string) {
  const specialCharactersMap: Record<string, string> = {
    á: "_a1_",
    à: "_a2_",
    â: "_a3_",
    ã: "_a4_",
    ä: "_a5_",
    Á: "_A1_",
    À: "_A2_",
    Â: "_A3_",
    Ã: "_A4_",
    Ä: "_A5_",
    é: "_e1_",
    è: "_e2_",
    ê: "_e3_",
    ë: "_e4_",
    É: "_E1_",
    È: "_E2_",
    Ê: "_E3_",
    Ë: "_E4_",
    í: "_i1_",
    ì: "_i2_",
    î: "_i3_",
    ï: "_i4_",
    Í: "_I1_",
    Ì: "_I2_",
    Î: "_I3_",
    Ï: "_I4_",
    ó: "_o1_",
    ò: "_o2_",
    ô: "_o3_",
    õ: "_o4_",
    ö: "_o5_",
    Ó: "_O1_",
    Ò: "_O2_",
    Ô: "_O3_",
    Õ: "_O4_",
    Ö: "_O5_",
    ú: "_u1_",
    ù: "_u2_",
    û: "_u3_",
    ü: "_u4_",
    Ú: "_U1_",
    Ù: "_U2_",
    Û: "_U3_",
    Ü: "_U4_",
    ç: "_c1_",
    Ç: "_C1_",
    ñ: "_n1_",
    Ñ: "_N1_",
    // Special characters
    "!": "_exclamation_",
    "@": "_at_",
    "#": "_hash_",
    $: "_dollar_",
    "%": "_percent_",
    "&": "_and_",
    "*": "_asterisk_",
    "(": "_open_parenthesis_",
    ")": "_close_parenthesis_",
    "-": "_hyphen_",
    _: "_underscore_",
    "+": "_plus_",
    "=": "_equal_",
    "{": "_open_brace_",
    "}": "_close_brace_",
    "[": "_open_bracket_",
    "]": "_close_bracket_",
    "|": "_pipe_",
    "\\": "_backslash_",
    ":": "_colon_",
    ";": "_semicolon_",
    '"': "_double_quote_",
    "'": "_single_quote_",
    "<": "_less_than_",
    ">": "_greater_than_",
    ",": "_comma_",
    ".": "_dot_",
    "?": "_question_",
    "/": "_slash_",
    "`": "_backtick_",
    "~": "_tilde_",
    "°": "_degree_",
    "ª": "_feminine_ordinal_",
    "º": "_masculine_ordinal_",
    "¹": "_superscript_1_",
    "²": "_superscript_2_",
    "³": "_superscript_3_",
    "¼": "_fraction_1/4_",
    "½": "_fraction_1/2_",
    "¾": "_fraction_3/4_",
    "€": "_euro_",
    "£": "_pound_",
    "¢": "_cent_",
    "¥": "_yen_",
    "§": "_section_",
    "©": "_copyright_",
    "®": "_registered_",
    "™": "_trademark_",
    "×": "_multiplication_",
    "÷": "_division_",
    "±": "_plus_minus_",
    "¬": "_negation_",
    "¦": "_broken_bar_",
    "«": "_double_left_",
    "»": "_double_right_",
    "¡": "_inverted_exclamation_",
    "¿": "_inverted_question_",
    "¨": "_diaeresis_",
    "¯": "_macron_",
    "ˆ": "_circumflex_",
    "˜": "_tilde_",
    "¸": "_cedilla_",
    "˚": "_ring_",
    "˛": "_ogonek_",
    "˝": "_double_acute_",
    "˙": "_dot_above_",
    "^": "_caret_",
  }
  let codifiedString = ""

  for (const char of string) {
    codifiedString += specialCharactersMap[char] ?? char
  }

  return codifiedString.trim()
}
