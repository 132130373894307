const SustentabilityIcon = () => {
  return (
    <svg 
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M1.85254 8.84314V1.55882H9.13685C13.5484 1.55882 17.8184 2.41549 21.9577 4.13433C26.0958 5.85272 29.7373 8.28645 32.8919 11.4411C35.0658 13.615 36.865 15.9923 38.2954 18.574C39.7338 21.1703 40.8408 23.9449 41.6152 26.9016L42.4262 29.9983L44.2869 27.3934C44.6155 26.9334 44.9784 26.4877 45.3763 26.0566C45.8005 25.5971 46.2623 25.1174 46.7622 24.6175C49.9168 21.4629 53.5583 19.0292 57.6964 17.3108C61.8327 15.5932 66.1391 14.7353 70.627 14.7353H77.9114V22.0196C77.9114 26.5075 77.0535 30.814 75.3359 34.9502C73.6175 39.0884 71.1837 42.7298 68.0291 45.8844C64.8746 49.039 61.2531 51.4529 57.1558 53.1338C53.0563 54.8156 48.7662 55.6569 44.2741 55.6569H42.7741V57.1569V77.6176H36.9898V44.0902V42.5902H35.4898C31.0019 42.5902 26.6954 41.7323 22.5592 40.0147C18.421 38.2963 14.7796 35.8626 11.625 32.708C8.47253 29.5555 6.05877 25.9175 4.37728 21.7838C2.69379 17.6452 1.85254 13.3353 1.85254 8.84314ZM35.4898 36.6961H36.9898V35.1961C36.9898 31.4838 36.2732 27.9349 34.8388 24.562C33.408 21.1977 31.3768 18.201 28.7544 15.5786C26.1319 12.9561 23.1353 10.9249 19.771 9.49415C16.3981 8.05971 12.8491 7.34314 9.13685 7.34314H7.63685V8.84314C7.63685 12.5497 8.33288 16.1101 9.725 19.513C11.119 22.9205 13.1344 25.9424 15.7625 28.5705C18.3906 31.1985 21.4125 33.214 24.8199 34.6079C28.2229 36.0001 31.7832 36.6961 35.4898 36.6961ZM42.7741 48.3725V49.8725H44.2741C47.9807 49.8725 51.5249 49.1765 54.8947 47.7821C58.2651 46.3874 61.2664 44.3722 63.8916 41.7469C66.5169 39.1217 68.5483 36.1043 69.9784 32.7029C71.4105 29.2969 72.127 25.7318 72.127 22.0196V20.5196H70.627C66.9148 20.5196 63.3497 21.2361 59.9437 22.6682C56.5423 24.0984 53.525 26.1298 50.8997 28.755C48.2745 31.3803 46.2592 34.3815 44.8646 37.752C43.4702 41.1217 42.7741 44.666 42.7741 48.3725Z" 
        fill="currentColor"
        stroke="#B0049C"
        strokeWidth="3"
      />
    </svg>
  );
};

export default SustentabilityIcon;
