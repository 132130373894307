const ArrowListIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.33399 6.9489C3.33399 6.75317 3.42125 6.56659 3.57113 6.43488C3.87466 6.1733 4.33756 6.1916 4.61833 6.47513L10.0498 12.0177L15.3769 6.47879C15.6558 6.19343 16.1187 6.1733 16.4241 6.43306C16.72 6.68366 16.7504 7.11719 16.4905 7.40255C16.4848 7.40803 16.481 7.41352 16.4753 7.41901L10.6037 13.5213C10.4634 13.6677 10.2661 13.75 10.0593 13.75C9.85248 13.7518 9.65328 13.6713 9.511 13.5268L3.53129 7.42267C3.40441 7.29531 3.33369 7.12549 3.33399 6.9489Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ArrowListIcon
