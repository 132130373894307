import styled from "styled-components"

interface IArrowTopButton {
  $showButton: boolean
}

export const ArrowTopButton = styled.div<IArrowTopButton>`
  position: fixed;
  bottom: 35px;
  right: 96px;
  transition: all 0.2s ease-in-out;
  opacity: ${(props) => (props.$showButton ? "1" : "0")};
  visibility: ${(props) => (props.$showButton ? "visible" : "hidden")};

  button {
    all: unset;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary-orange, #fff);
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    display: none;
  }
`
