import * as S from "./styles";
import AurenVivoSrcSm from "../../../assets/img/auren_vivo_logo_sm.png";

const AboutComponent = () => {
  return (
    <S.About>
      <S.AboutRow>
        <h2>Sobre a GUD Energia</h2>
      </S.AboutRow>

      <S.AboutRow>
        <S.AboutText>
          Unindo a experiência e a força de Vivo e Auren, chegamos para facilitar a entrada 
          no Mercado Livre de Energia e auxiliar empresas a <strong>reduzirem custos com energia de 
          maneira simples e sustentável</strong>.
        </S.AboutText>
      </S.AboutRow>

      <S.AboutRow>
        <img src={AurenVivoSrcSm} />
      </S.AboutRow>
      <span className="aboutDesk" />
    </S.About>
  );
};

export default AboutComponent;
