export const questionsContent = [
  {
    question: "O que é o Mercado Livre de Energia?",
    answer:
      "<p>O Mercado Livre de Energia é um ambiente de negociação onde consumidores podem escolher livremente seu fornecedor de energia elétrica, negociando as condições comerciais de acordo com suas necessidades e perfil de consumo, deixando sua conta de luz mais barata.</p>",
    apiInfo: "o_que_e_mercado_livre",
  },

  {
    question: "Quem pode fazer a migração?",
    answer:
      "<p>Podem migrar para o Mercado Livre de Energia todos os consumidores do Grupo A, independente do volume consumido.</p>",
    apiInfo: "quem_pode_migrar",
  },

  {
    question: "O que é Grupo A?",
    answer:
      "<p>Grupo A é como são classificados os consumidores que estão conectados em média ou alta tensão, acima de 2,3 kV. São empresas que possuem um alto consumo de energia elétrica e contratam uma demanda de fornecimento junto a distribuidora.</p>",
    apiInfo: "o_que_e_grupo_a",
  },

  {
    question: "Como sei se pertenço ao Grupo A?",
    answer:
      "<p>É só olhar na sua conta de luz, no campo “Classificação da Unidade Consumidora” qual o seu Grupo e Subgrupo. Se aparecer uma das seguintes siglas: A4, A3a, A3, A2, A1 ou AS, sua empresa faz parte do chamado “Grupo A”.</p>",
    apiInfo: "como_sei_grupo_a",
  },

  {
    question:
      "Qual é a diferença entre o Mercado Livre de Energia e o Modelo Tradicional?",
    answer:
      "<p>No modelo tradicional de compra de energia, também conhecido como Mercado Cativo ou Mercado Regulado, você não tem opção de escolha. A conta de luz chega na sua empresa e você precisa pagar o valor cobrado pela sua distribuidora atual. Já no Mercado Livre, você pode comprar energia de quem quiser, e o valor é negociado entre você e o fornecedor escolhido.</p>",
    apiInfo: "diferencas_mercado",
  },

  {
    question: "Por que preciso mandar a fatura?",
    answer:
      "<p>Pedimos uma cópia da sua conta de luz para que possamos identificar algumas informações importantes como o seu grupo de consumo, demanda contratada, distribuidora atual e histórico de consumo, e assim realizar uma proposta personalizada que atenda às necessidades do seu negócio.</p>",
    apiInfo: "porque_mandar_fatura",
  },

  {
    question: "Ao mandar a fatura estou me comprometendo de alguma forma?",
    answer:
      "<p>Não, ao enviar a fatura realizaremos uma proposta personalizada sem nenhum custo ou compromisso.</p>",
    apiInfo: "mandar_fatura_compromet",
  },

  {
    question: "Esse mercado surgiu agora?",
    answer:
      "<p>Na verdade, o Mercado Livre de Energia já existe no Brasil desde 1995, mas você provavelmente só ouviu falar dele agora porque no começo só as grandes indústrias podiam realizar a migração, e recentemente ele foi liberado para todas as empresas do Grupo A. Hoje, esse mercado já conta com pouco mais de 35 mil unidades consumidoras, representando 37% de toda a energia consumida no país.</p>",
    apiInfo: "surgimento_mercado",
  },

  {
    question: "Posso ficar sem luz?",
    answer:
      "<p>O risco de ficar sem luz no Mercado Livre de Energia é o mesmo que você já tem hoje no Mercado Cativo, isso porque a sua distribuidora atual continua sendo a responsável pela entrega física da energia.</p>",
    apiInfo: "posso_ficar_sem_luz",
  },

  {
    question: "Qual é o papel da distribuidora local no Mercado Livre de Energia?",
    answer:
      "<p>A distribuidora local ainda será responsável pela entrega física da energia elétrica, ou seja, a energia continuará chegando pelo mesmo fio, e você continuará pagando uma fatura da distribuidora referente a infraestrutura de distribuição, porém bem mais barata que hoje. Ela também continua responsável pela manutenção da rede elétrica e leitura do medidor.</p>",
    apiInfo: "qual_papel_distribuidora",
  },

  {
    question: "O que acontece se faltar luz no Mercado Livre de Energia?",
    answer:
      "<p>A entrega da energia elétrica ainda é de responsabilidade da distribuidora, portanto, em caso de falta de luz, os mesmos canais de atendimento que você utiliza hoje devem ser acionados para solucionar o problema.</p>",
    apiInfo: "faltar_luz_mercado_livre",
  },

  {
    question:
      "Minha relação com a distribuidora vai piorar se eu deixar de comprar dela?",
    answer:
      "<p>Não. Sua distribuidora local continuará responsável pela entrega da energia e pelos serviços de manutenção da rede elétrica, independentemente de qual seja o seu fornecedor escolhido no Mercado Livre de Energia.</p>",
    apiInfo: "relacao_distribuidora",
  },

  {
    question: "Preciso parar minha empresa para realizar a adequação do medidor?",
    answer:
      "<p>Depende das características da sua instalação elétrica atual. Em alguns casos, pode ser necessário realizar adequações durante o horário comercial, mas quando isso acontecer, a distribuidora entrará em contato e o serviço será agendado com antecedência.</p>",
    apiInfo: "preciso_parar_empresa",
  },

  {
    question:
      "Como é feita a medição e a cobrança da energia elétrica no Mercado Livre?",
    answer:
      "<p>A medição da energia continua sendo realizada através da leitura dos relógios da sua empresa pela distribuidora e é auditada remotamente pela CCEE (Câmara de Comercialização de Energia Elétrica). A cobrança é feita com base no valor da energia negociado entre você e o fornecedor no momento da assinatura do contrato.</p>",
    apiInfo: "como_e_feita_medicao",
  },

  {
    question:
      "Ao mudar para o Mercado Livre de Energia eu continuo pagando apenas pela quantidade consumida?",
    answer:
      "<p>Sim, a quantidade de energia consumida continua sendo contabilizada mensalmente, e você paga apenas pela quantidade utilizada.</p>",
    apiInfo: "ao_mudar_pagando_consumo",
  },

  {
    question:
      "Tenho painel solar na minha empresa, posso migrar ao Mercado Livre de Energia mesmo assim?",
    answer:
      "<p>A Geração Distribuída (GD), ou uso de placa solar fotovoltaicas, não impede a migração para o Mercado Livre de Energia, mas por enquanto nós não atendemos clientes nessa modalidade.</p>",
    apiInfo: "tenho_painel_solar",
  },
  {
    question: "Existe um limite de energia que eu posso usar?",
    answer:
      "<p>Não, você pode usar a quantidade de energia que precisar, mas existe uma variação no preço. De 0% a 200% da quantidade contratada, o valor cobrado será o definido no contrato. O que passar disto, será cobrado no valor de mercado, com base no PLD. Ex.: Se você contratou 10 MWh: poderá gastar entre 0 e 20 MWh, pagando o valor unitário acordado em contrato. Se gastar 30 MWh, os primeiros 20 MWh serão contabilizados pelo valor contratual e os 10 MWh adicionais serão valorados com base no PLD, podendo sofrer variações em relação ao valor contratual.</p>",
    apiInfo: "existe_limite_energia",
  },

  {
    question: "Se eu quiser, posso voltar para o Mercado Cativo?",
    answer:
      "<p>Pode sim. Apesar de não ser vantajoso, já que os preços no Mercado Livre de Energia são mais atrativos, caso queira é possível retornar ao Mercado Cativo após o período estipulado pela sua distribuidora atual, que pode ser de até 5 anos.</p>",
    apiInfo: "voltar_mercado_cativo",
  },

  {
    question: "O Mercado Livre de Energia é confiável?",
    answer:
      "<p>Sim, o Mercado Livre de Energia já existe desde 1995 e atualmente 37% de toda a energia consumida no Brasil já é consumida nessa modalidade.</p>",
    apiInfo: "mercado_livre_confiavel",
  },
  {
    question: "Como você garante que a energia é de fontes renováveis?",
    answer:
      "<p>Toda energia que comercializamos possui o I-REC, ou certificado de energia renovável, que garante a rastreabilidade de uma fonte de geração de energia limpa e renovável e garante que você estará consumindo energia limpa.</p>",
    apiInfo: "garante_fontes_renovaveis",
  },
  {
    question: "Quem é a GUD?",
    answer: "<p>Somos a GUD Energia, uma joint-venture entre a Auren Energia, uma das maiores geradoras de energia do Brasil com a Vivo, referência em tecnologia e inovação.</p>" + "<p><a href='https://image.marketing.aurenenergia.com.br/lib/fe3915707564047f7d1778/m/1/4bec5ef4-c619-43ec-866f-fa1bc3bc4f28.pdf' target='_blank'>Saiba mais</a></p>",
    apiInfo: "quem_e_gud",
  }, 
]
