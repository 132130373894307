const AgilizePaperIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M22.656 25.7408H9.34405C9.09445 25.7408 8.88965 25.9456 8.88965 26.1952C8.88965 26.4448 9.09445 26.6496 9.34405 26.6496H22.656C22.912 26.6496 23.1104 26.4448 23.1104 26.1952C23.1104 25.9456 22.9056 25.7408 22.656 25.7408ZM22.656 20.8576H9.34405C9.09445 20.8576 8.88965 21.0624 8.88965 21.312C8.88965 21.5616 9.09445 21.7664 9.34405 21.7664H22.656C22.912 21.7664 23.1104 21.5616 23.1104 21.312C23.1104 21.0624 22.9056 20.8576 22.656 20.8576ZM22.656 15.9744H9.34405C9.09445 15.9744 8.88965 16.1792 8.88965 16.4288C8.88965 16.6784 9.09445 16.8832 9.34405 16.8832H22.656C22.912 16.8832 23.1104 16.6784 23.1104 16.4288C23.1104 16.1792 22.9056 15.9744 22.656 15.9744Z"
        fill="currentColor"
      />
      <path
        d="M27.4112 9.00475L18.5408 0.134348C18.5024 0.0959476 18.4512 0.0639477 18.4 0.0383477H18.3936C18.3872 0.0383477 18.3808 0.0319477 18.3744 0.0319477C18.336 0.0191477 18.2976 0.00634766 18.2592 0.00634766H5.792C5.1136 0.00634766 4.448 0.671948 4.448 1.35035V30.6367C4.448 31.3407 5.088 31.9807 5.792 31.9807H26.208C26.9248 31.9807 27.552 31.3535 27.552 30.6367V9.33115C27.5456 9.20955 27.5008 9.09435 27.4112 9.00475ZM18.6688 1.55515L25.9904 8.87675H22.656C20.2368 8.87675 18.6752 7.30875 18.6752 4.89595L18.6688 1.55515ZM26.2016 31.0655H5.7856C5.5872 31.0655 5.3504 30.8287 5.3504 30.6303V1.34395C5.3504 1.17115 5.6128 0.908748 5.7856 0.908748H17.7536V4.88955C17.7536 6.32315 18.2208 7.54555 19.104 8.42875C19.9936 9.31195 21.216 9.77915 22.6432 9.77915H26.624V30.6239C26.6368 30.8415 26.4128 31.0655 26.2016 31.0655Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default AgilizePaperIcon
