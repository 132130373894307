import { FormEvent, useState, useContext } from "react";

import * as S from "./styles";
import ArrowListIcon from "../../../assets/icons/ArrowListIcon";
import states from "../../../mocks/states";
import axios from "axios";
import SearchIcon from "../../../assets/icons/SearchIcon";
import { GlobalContext } from "../../../context/globalContext";
import currencyFormatter from "../../../utils/currencyFormatter";
import IneligibleScreen from "../../IneligibleScreen";
import { formatNumberToBRL } from "../../../utils/formatInputNumberToBrl";
import { ButtonPrimary } from "@telefonica/mistica";
import { api } from "../../../lib/api";

type TCitiesList = Array<{ city: string; id: number }>;

// TODO: mover para o arquivo adequado
declare global {
  interface Window {
    dataLayer: Array<{
      event: string;
      tipoLead: string | null;
      consumo: number;
      tel: string;
      email: string;
    }>;
  }
}

const EconomyCalculation = () => {
  const {
    showIneligibleScreen,
    selectedCity,
    selectedState,
    monthlyValue,
    setSelectedCity,
    setSelectedState,
    setMonthlyValue,
    setShowSimulationResult,
    setResultInformations,
    setShowIneligibleScreen,
  } = useContext(GlobalContext);

  const [openStatesList, setOpenStatesList] = useState(false);
  const [monthlyValueError, setMonthlyValueError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [cityError, setCityError] = useState(false);

  const [openCitiesList, setOpenCitiesList] = useState(false);
  const [citiesList, setCitiesList] = useState<TCitiesList>([]);

  const [searchForACity, setSearchForACity] = useState("");
  const [loadingSimulation, setLoadingSimulation] = useState(false);
  const [apiError, setApiError] = useState("");

  let displayCities = [] as TCitiesList;

  function removeSpecialCharactersToFilter(value: string) {
    const valueFormatted = value
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "")
      .replaceAll(" ", "")
      .toLowerCase();

    return valueFormatted;
  }

  if (searchForACity.length) {
    displayCities = citiesList.filter((cities) => {
      const cityValueWritten = removeSpecialCharactersToFilter(searchForACity);

      const cityToGet = removeSpecialCharactersToFilter(cities.city);

      return cityToGet.includes(cityValueWritten);
    });
  } else {
    displayCities = citiesList.sort((a, b) => a.city.localeCompare(b.city));
  }

  const formatCalculationValue = monthlyValue
    ?.replaceAll(".", "")
    .replaceAll(",", ".");

  async function handleGetCitiesFromSelectedState(state: string) {
    setOpenCitiesList(false);
    setCitiesList([]);
    setSelectedCity(null);

    const url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`;

    try {
      const stateResponse = await axios.get(url);

      const cities = stateResponse.data.map(
        (city: { nome: string; id: number }) => {
          return { city: city.nome, id: city.id };
        }
      );

      setCitiesList(cities);
    } catch (e) {
      console.log("Ocorreu um erro ao buscar pelas cidades...");
    }
  }

  function handleShowInelegibleScreen() {
    setShowIneligibleScreen(true);
    setLoadingSimulation(false);
    setSelectedState("");
  }

  async function handleSendSimulation(e: FormEvent) {
    e.preventDefault();

    if (!selectedState) {
      setStateError(true);
    }

    if (!selectedCity) {
      setCityError(true);
    }

    if (+formatCalculationValue <= 0) {
      return setMonthlyValueError(true);
    }

    if (!selectedCity?.id) return;

    setLoadingSimulation(true);

    const inputElement = document.querySelector<HTMLInputElement>(
      "#campoContaMensal > input"
    );

    if (inputElement && inputElement.value) {
      const consumo = +inputElement.value.split(",")[0].replace(".", "");

      let tipoLead = "";

      if (consumo >= 10000 && consumo <= 200000) {
        tipoLead = "lead_qualificado";
      } else {
        tipoLead = "lead_nao_qualificado";
      }

      localStorage.setItem("tipoLead", tipoLead);
      localStorage.setItem("consumo", consumo.toString());

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "firstForm_conversion",
        tipoLead: tipoLead,
        consumo: consumo,
        email: "",
        tel: "",
      });
    }

    try {
      const { data: getSimulationResponse } = await api.get(
        import.meta.env.VITE_APP_API_GUARANTEED_DISCOUNT,
        {
          params: {
            fatura_digitada: formatCalculationValue,
            municipio_ibge: selectedCity.id,
          },
        }
      );

      if (getSimulationResponse.sucesso) {
        const formattedDiscount = currencyFormatter(
          getSimulationResponse.dados.desconto_financeiro_gud
        );
        const formattedPercentage = String(
          Number(getSimulationResponse.dados.desconto_percentual * 100).toFixed(
            0
          )
        );

        setResultInformations({
          economy: formattedDiscount,
          percentage: formattedPercentage,
          city: selectedCity.city,
          cityId: selectedCity.id,
          monthlyValue: formatCalculationValue,
        });

        if (Number(formatCalculationValue) < 5000) {
          handleShowInelegibleScreen();
          return;
        }

        setShowSimulationResult(true);
        window.scrollTo({
          top: window.innerWidth ? 2415 : 1460,
          left: 0,
          behavior: "smooth",
        });
      } else {
        throw {
          error: getSimulationResponse.mensagem_usuario,
        };
      }
    } catch (e) {
      const errorMsg = e as { error: string };

      setApiError(errorMsg.error);
    } finally {
      setLoadingSimulation(false);
    }
  }

  return (
    <>
      {!showIneligibleScreen ? (
        <S.CalculationRoot>
          <S.CalculationWrapper>
            <S.InfoCol>
              <S.MainHeader>
                A conta de luz da sua empresa até 30% mais barata
              </S.MainHeader>

              <S.HeaderText>
                Traga a conta de luz da sua empresa para a GUD e reduza suas
                despesas sem investimento adicional.
              </S.HeaderText>
            </S.InfoCol>
            <S.CalculationCol>
              <S.CalculationForm onSubmit={handleSendSimulation}>
                <h2>Simule agora quanto sua empresa pode economizar</h2>
                <S.InputsWrapper>
                  <div className="labelDiv">
                    Minha empresa está localizada em:
                    <S.InputSection onClick={(e) => e.stopPropagation()}>
                      <S.TriggerWrapper
                        id="campoEstado"
                        className="state-field"
                        $floatText={Boolean(selectedState)}
                        $changeColor={Boolean(selectedState)}
                      >
                        <button
                          className={openStatesList ? "openedList" : ""}
                          type="button"
                          onClick={() => {
                            setOpenStatesList(!openStatesList);
                            setOpenCitiesList(false);
                          }}
                        >
                          <p className="miniText">Estado (UF)</p>
                          {selectedState} <ArrowListIcon />
                        </button>
                        <S.List $openList={openStatesList}>
                          {states.map((state) => {
                            return (
                              <li
                                onClick={() => {
                                  setStateError(false);
                                  setSelectedState(state);
                                  setOpenStatesList(false);
                                  handleGetCitiesFromSelectedState(state);
                                }}
                                key={state}
                              >
                                {state}
                              </li>
                            );
                          })}
                        </S.List>
                        <S.InputError>
                          {stateError && "Obrigatório"}
                        </S.InputError>
                      </S.TriggerWrapper>

                      <S.TriggerWrapper
                        id="campoMunicipio"
                        className="city-field"
                        $floatText={Boolean(selectedCity)}
                        $changeColor={Boolean(selectedCity)}
                      >
                        <button
                          disabled={!citiesList.length}
                          className={openCitiesList ? "openedList" : ""}
                          type="button"
                          onClick={() => {
                            setOpenCitiesList(!openCitiesList);
                            setOpenStatesList(false);
                          }}
                        >
                          <p className="miniText">Município</p>
                          {selectedCity?.city} <ArrowListIcon />
                        </button>
                        <S.List
                          onClick={(e) => e.stopPropagation()}
                          $openList={openCitiesList}
                        >
                          <S.SearchCityInput>
                            <input
                              type="text"
                              onChange={({ target }) =>
                                setSearchForACity(target.value)
                              }
                            />
                            <SearchIcon />
                          </S.SearchCityInput>
                          {displayCities.map(({ city, id }) => {
                            return (
                              <li
                                key={id}
                                onClick={() => {
                                  setCityError(false);

                                  setSelectedCity({ city, id });
                                  setOpenCitiesList(false);
                                }}
                              >
                                {city}
                              </li>
                            );
                          })}
                        </S.List>
                        <S.InputError>
                          {cityError && "Obrigatório"}
                        </S.InputError>
                      </S.TriggerWrapper>
                    </S.InputSection>
                  </div>

                  <div className="labelDiv">
                    Com uma conta de energia mensal de:
                    <S.FloatingInput
                      id="campoContaMensal"
                      $floatTextError={monthlyValueError}
                      $floatText={Boolean(monthlyValue?.length)}
                    >
                      <input
                        value={monthlyValue}
                        type="text"
                        onChange={(e) => {
                          setMonthlyValueError(false);
                          formatNumberToBRL(e, setMonthlyValue);
                        }}
                      />
                      <p className="miniText">Consumo em R$</p>
                    </S.FloatingInput>
                    <S.InputError>
                      {monthlyValueError &&
                        (!monthlyValue ? "Obrigatório" : "Valor inválido")}
                    </S.InputError>
                  </div>
                </S.InputsWrapper>
                {apiError && (
                  <S.ApiError>
                    <p>{apiError}</p>
                  </S.ApiError>
                )}
                <S.SimulateButton
                  id="btnQueroEconomizar"
                  $formLoading={loadingSimulation}
                >
                  <ButtonPrimary
                    loadingText="Calculando..."
                    submit
                    showSpinner={loadingSimulation}
                    className={(!monthlyValueError && !stateError && !cityError && selectedCity?.id && selectedState && monthlyValue) ? 'firstForm-conversion' : ''}
                  >
                    Quero economizar
                  </ButtonPrimary>
                </S.SimulateButton>
              </S.CalculationForm>
            </S.CalculationCol>
          </S.CalculationWrapper>
        </S.CalculationRoot>
      ) : (
        <IneligibleScreen />
      )}
      <span className="economySimulation" />
    </>
  );
};

export default EconomyCalculation;
