import { createGlobalStyle } from "styled-components";

import VotorantimSansLight from "../assets/fonts/VotorantimSans-Light.woff";
import VotorantimSansRegular from "../assets/fonts/VotorantimSans-Regular.woff";
import VotorantimSansMedium from "../assets/fonts/VotorantimSans-Medium.woff";
import VotorantimSansBold from "../assets/fonts/VotorantimSans-Bold.woff";
import VotorantimSansBlack from "../assets/fonts/VotorantimSans-Black.woff";
import SourceSans3Light from "../assets/fonts/SourceSans3-Light.woff";
import SourceSans3Regular from "../assets/fonts/SourceSans3-Regular.woff";
import SourceSans3Medium from "../assets/fonts/SourceSans3-Medium.woff";
import SourceSans3Bold from "../assets/fonts/SourceSans3-Bold.woff";
import SourceSans3Black from "../assets/fonts/SourceSans3-Black.woff";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'VotorantimSans-Light';
    src: local(VotorantimSansLight),
    url(${VotorantimSansLight}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'VotorantimSans-Regular';
    src: local(VotorantimSansRegular),
    url(${VotorantimSansRegular}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'VotorantimSans-Medium';
    src: local(VotorantimSansMedium),
    url(${VotorantimSansMedium}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'VotorantimSans-Bold';
    src: local(VotorantimSansBold),
    url(${VotorantimSansBold}) format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'VotorantimSans-Black';
    src: local(VotorantimSansBlack),
    url(${VotorantimSansBlack}) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'SourceSans3-Light';
    src: local(SourceSans3Light),
    url(${SourceSans3Light}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'SourceSans3-Regular';
    src: local(SourceSans3Regular),
    url(${SourceSans3Regular}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'SourceSans3-Medium';
    src: local(SourceSans3Medium),
    url(${SourceSans3Medium}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'SourceSans3-Bold';
    src: local(SourceSans3Bold),
    url(${SourceSans3Bold}) format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'SourceSans3-Black';
    src: local(SourceSans3Black),
    url(${SourceSans3Black}) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  :root {
    --primary: #b0049c;
    --secondary: #fff;
    --secondary-pink: #f906ce;
    --secondary-salmon: #720966;
    --secondary-orange: #ff8c7a;
    --tertiary-orange: #ff3e05;
    --tertiary-blue: #30b0f6;
    
    --white: #fff;
    --black1: #000;
    --black2: #1d1d1d;
    --black3: #282828;
    --gray1: #303130;
    --gray2: #626362;
    --gray3: #a0a2a0;
    --gray4: #bdbdbd;
    --gray5: #faf8fa;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: "VotorantimSans-Regular", sans-serif;
    box-sizing: border-box;


    p, 
    h1, 
    h2, 
    h3, 
    h4, 
    h5 {
        margin: 0;
    }

    ul {
        padding: 0;
        list-style: none;
    }

    button {
        border: 0;
        background-color: transparent;
        cursor: pointer;
    }

    input {
      border: 0;

      &:focus, 
      &:active {
        outline: none;
      }
    }
  }
`;

export default GlobalStyle;
