const EconomyIcon = () => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      version="1.2" 
      viewBox="0 0 83 107" 
      width="83" 
      height="107"
    >
      <g>
        <path 
          fillRule="evenodd" 
          d="m63.1 105.4h-20.4v-8.2h-10.8v8.2h-20.4q-0.1-0.3-0.2-0.6c-0.3-1-0.7-2.4-1.2-4.1-0.9-3.5-2.2-8.2-3.5-13.3-2.6-10.4-5.1-22-5.1-28.1 0-11.8 9.6-21.4 21.4-21.4h21.2l0.4-0.5c3.5-4.7 9.1-7.7 15.4-7.7 2.8 0 5 2.2 5 4.9q0 1-0.4 1.9c-0.6 1.5-1.2 3.2-1.4 5l-0.1 0.7 0.4 0.4 9.4 9.3 0.3 0.4h8.2v26.6l-10.7 3.5-0.6 0.2-0.2 0.7zm12.5-47.4h-4.9l-13.7-13.7q0.1-3.5 1-7l0.6-2.1-2.1 0.6c-4.1 1-7.5 3.9-9.1 7.8h-24.6c-8.6 0-15.6 7-15.6 15.7 0 4 1.3 11 2.9 18.3 1.7 7.4 3.7 15.2 5.4 21.1l0.2 0.9h10.4v-8.2h22.2v8.2h10.5l0.2-0.9 6.3-20.5 9.4-3.2 0.9-0.3zm-20.6 3.3c0-1.6 1.3-2.9 2.8-2.9 1.6 0 2.9 1.3 2.9 2.9 0 1.6-1.3 2.9-2.9 2.9-1.5 0-2.8-1.3-2.8-2.9zm-10.8-11.1v5.7h-18.1v-5.7z"
          fill="currentColor"
          stroke="#b0049c"
          strokeWidth="2.5"
        />
      </g>

      <g>
        <path 
          fillRule="evenodd" 
          d="m41.2 28.5c-5.5 0-9.9-4.5-9.9-9.9 0-5.5 4.4-9.9 9.9-9.9 5.4 0 9.9 4.4 9.9 9.9 0 5.4-4.5 9.9-9.9 9.9zm-7-19.8h-2.4c1.4-3.8 5.1-6.6 9.4-6.6 4.3 0 7.9 2.8 9.3 6.6h-2.4c-1.2-2.6-3.9-4.4-6.9-4.4-3.1 0-5.7 1.8-7 4.4zm7 17.6c4.2 0 7.7-3.5 7.7-7.7 0-4.3-3.5-7.7-7.7-7.7-4.3 0-7.7 3.4-7.7 7.7 0 4.2 3.4 7.7 7.7 7.7zm1.1-6.6v3.3h-2.2v-3.3h-3.3v-2.2h3.3v-3.3h2.2v3.3h3.3v2.2z"
          fill="currentColor"
        />
      </g>

      <defs>
        <clipPath id="clip0_7094_13331">
          <rect
            width="26.3529"
            height="26.3529"
            fill="white"
            transform="translate(58.3525 0.117645) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EconomyIcon;
