import {
  FooterCol,
  FooterContainer,
  FooterRow,
  FooterWrapper,
  SocialWrapper,
  SocialLink,
} from "./styles";

import {
  INSTAGRAM_URL,
  LINKEDIN_URL,
} from "../../mocks/externalUrls";

import LogoFooter from "../../assets/icons/LogoFooter";
import InstagramIcon from "../../assets/icons/social/InstagramIcon";
import LinkedinIcon from "../../assets/icons/social/LinkedinIcon";
import AurenVivoSrcSm from "../../assets/img/logo-auren-vivo-branco-negativo.png";


const FooterComponent = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterRow>
          <LogoFooter />

          <FooterCol>
            <span>Copyright © 2024 GUD Energia</span>
            <a target="__blank" href="/normas-de-privacidade">
              Termo de Privacidade
            </a>
          </FooterCol>
        </FooterRow>

        <FooterCol style={{ gap: "0.5rem" }}>
          <span>Uma empresa</span>
          <FooterRow>
            <img src={AurenVivoSrcSm} />
          </FooterRow>
        </FooterCol>

        <FooterCol>
          <span>Acompanhe nas redes sociais:</span>
          <SocialWrapper>
            <SocialLink target="_blank" href={INSTAGRAM_URL}>
              <InstagramIcon />
            </SocialLink>
            <SocialLink target="_blank" href={LINKEDIN_URL}>
              <LinkedinIcon />
            </SocialLink>
          </SocialWrapper>
        </FooterCol>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default FooterComponent;
