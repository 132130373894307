import styled from "styled-components";

export const FaqContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem 0 6rem 0;
  background-color: var(--gray5,#e5e5e5);
`;

export const FaqWrapper = styled.section`
  width: 100%;
  display: flex;
  max-width: 59.25rem;
  flex-direction: column;
  gap: 2rem;
  font-family: "SourceSans3-Regular";
  color: var(--black2, #1d1d1d);
  position: relative;

  @media (max-width: 768px) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
`;

export const TitleText = styled.h2`
  font-family: "SourceSans3-Bold";
  color: var(--black2, #1d1d1d);
  font-size: 2rem;
  line-height: 2.2rem;
  margin-bottom: 1rem;
  text-align: center;
`;

export const Faqs = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;

export const FaqAnchor = styled.span`
  position: absolute;
`;
