import { createContext, useState, Dispatch, SetStateAction } from "react"

interface IResultSchema {
  percentage: string
  economy: string
  city: string
  cityId: number
  monthlyValue: string
}

interface ISmsMessage {
  show: boolean
  hasattachedInvoice: boolean
}

interface Props {
  children: React.ReactNode
}

interface GlobalContextInterface {
  showSimulationResult: boolean
  setShowSimulationResult: Dispatch<SetStateAction<boolean>>

  resultInformations: IResultSchema | null
  setResultInformations: Dispatch<SetStateAction<IResultSchema | null>>

  showSucessMessageForResult: ISmsMessage
  setShowSucessMessageForResult: Dispatch<SetStateAction<ISmsMessage>>

  showIneligibleScreen: boolean
  setShowIneligibleScreen: Dispatch<SetStateAction<boolean>>

  monthlyValue: string
  setMonthlyValue: Dispatch<SetStateAction<string>>

  selectedCity: {
    city: string
    id: number
  } | null
  setSelectedCity: Dispatch<
    SetStateAction<{
      city: string
      id: number
    } | null>
  >

  selectedState: string
  setSelectedState: Dispatch<SetStateAction<string>>

  phoneNumber: string
  setPhoneNumber: Dispatch<SetStateAction<string>>
}

export const GlobalContext = createContext<GlobalContextInterface>(
  {} as GlobalContextInterface
)

const GlobalStorage = ({ children }: Props) => {
  const [showSimulationResult, setShowSimulationResult] = useState(false)
  const [showIneligibleScreen, setShowIneligibleScreen] = useState(false)
  const [resultInformations, setResultInformations] = useState<IResultSchema | null>(
    null
  )
  const [showSucessMessageForResult, setShowSucessMessageForResult] = useState({
    hasattachedInvoice: false,
    show: false,
  } as ISmsMessage)

  const [selectedState, setSelectedState] = useState("")
  const [selectedCity, setSelectedCity] = useState<{
    city: string
    id: number
  } | null>(null)
  const [monthlyValue, setMonthlyValue] = useState<string>("")

  const [phoneNumber, setPhoneNumber] = useState("")

  return (
    <GlobalContext.Provider
      value={{
        showSimulationResult,
        resultInformations,
        showSucessMessageForResult,
        showIneligibleScreen,
        selectedState,
        selectedCity,
        monthlyValue,
        phoneNumber,
        setSelectedState,
        setSelectedCity,
        setMonthlyValue,
        setShowIneligibleScreen,
        setShowSucessMessageForResult,
        setShowSimulationResult,
        setResultInformations,
        setPhoneNumber,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalStorage
