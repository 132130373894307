import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { INSTAGRAM_URL, LINKEDIN_URL } from "../../mocks/externalUrls";
import { headerLinks } from "../../mocks/headerLinks";
import { useIsMobile } from "../../hooks/useIsMobile";
import HamburguerMenu from "../../assets/icons/HamburguerMenu";
import CloseMenuIcon from "../../assets/icons/CloseMenuIcon";
import InstagramIcon from "../../assets/icons/social/InstagramIcon";
import LinkedinIcon from "../../assets/icons/social/LinkedinIcon";
import Cookie from "js-cookie";
import Logo from "../../assets/icons/Logo";
import * as S from "./styles";

const HeaderComponent = () => {
  const [activeMenu, setActiveMenu] = useState("Início");
  const [openMenuMobile, setOpenMenuMobile] = useState(false);

  const isMobile = useIsMobile(768);

  const navigate = useNavigate();
  const location = useLocation();

  function goToDesiredComponent(anchorElement: string, scrollValue: number) {
    // Se estiver não estiver na página inicial, move para lá primeiro
    if (location.pathname !== "/") {
      navigate("/", { state: { anchorElement, scrollValue } });
    }

    const yOffset = scrollValue;

    if (anchorElement === ".start") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      return;
    }

    const element = document.querySelector(anchorElement) as HTMLElement;

    if (element) {
      const scrollVal =
        element.getBoundingClientRect().top + window.scrollY + yOffset;

      window.scrollTo({ top: scrollVal, behavior: "smooth" });
    }
  }

  useEffect(() => {
    if (location.pathname === "/") {
      window.addEventListener("scroll", () => {
        const maxHeight =
          document.documentElement.scrollHeight - window.innerHeight;
        const offSet = (window.scrollY * 100) / maxHeight;

        for (const link of headerLinks) {
          const determineScrollPos =
            window.innerHeight <= 600 ? link.scrollPos - 2 : link.scrollPos;

          if (offSet >= determineScrollPos) {
            setActiveMenu(link.title);
          }
        }
      });
    }

    if (!isMobile) {
      setOpenMenuMobile(false);
    }
  }, [isMobile]);

  const acceptCookies = Cookie.get("vivo-cookie-auth");

  if (acceptCookies) {
    const getBody = document.querySelector("body") as HTMLElement;

    if (openMenuMobile) {
      getBody.style.overflow = "hidden";
    } else {
      getBody.style.overflow = "unset";
    }
  }

  return (
    <S.Header>
      <S.HeaderWrapper>
        <S.LogoLink href="/">
          <Logo />
        </S.LogoLink>
        <S.HamburguerMenuTrigger
          onClick={() => setOpenMenuMobile(!openMenuMobile)}
          type="button"
        >
          {openMenuMobile ? <CloseMenuIcon /> : <HamburguerMenu />}
        </S.HamburguerMenuTrigger>
        <S.NavHeader $openMobileMenu={openMenuMobile}>
          <ul>
            {headerLinks.map((link) => {
              return (
                <li
                  className={`${activeMenu === link.title ? "active" : ""}`}
                  onClick={() => {
                    setOpenMenuMobile(false);

                    goToDesiredComponent(
                      link.anchorClass,
                      window.innerWidth <= 1024 ? link.anchorMob : link.anchor
                    );
                  }}
                  key={link.title}
                >
                  {link.title}
                </li>
              );
            })}

            <li>
              <S.SocialWrapper>
                <S.SocialLink target="_blank" href={INSTAGRAM_URL}>
                  <InstagramIcon />
                </S.SocialLink>
                <S.SocialLink target="_blank" href={LINKEDIN_URL}>
                  <LinkedinIcon />
                </S.SocialLink>
              </S.SocialWrapper>
            </li>
          </ul>
        </S.NavHeader>
      </S.HeaderWrapper>
    </S.Header>
  );
};

export default HeaderComponent;
