const ZeroInvestmentIcon = () => {
  return (
    <svg
      width="74"
      height="72"
      viewBox="0 0 74 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.0011 0C16.5916 0 0 16.1444 0 36.0075C0 55.8707 16.5924 72 37.0011 72C57.4098 72 74 55.8707 74 36.0075C74 16.1444 57.4111 0 37.0011 0ZM37.0011 3.77393C45.4579 3.77393 53.1626 6.8487 59.0112 11.9114L48.0062 22.6224V19.3902H39.648V12.2038H35.7702V19.3911H33.7785C28.4377 19.3911 24.0043 23.5128 24.0043 28.638C24.0043 33.3192 27.7038 37.1656 32.4152 37.7966L12.2419 57.4277C7.03896 51.739 3.88042 44.2401 3.88042 36.0082C3.88042 18.1849 18.6871 3.77457 37.0015 3.77457L37.0011 3.77393ZM61.7527 14.576C66.9583 20.2688 70.1178 27.7728 70.1178 36.0073C70.1178 53.8306 55.3153 68.2259 37.0011 68.2259C28.5426 68.2259 20.8328 65.1562 14.9841 60.096L25.9927 49.3832V52.6122H35.7704V59.7986H39.6482V52.6122H40.2351C45.5759 52.6122 49.9937 48.4869 49.9937 43.3617C49.9937 38.6771 46.3002 34.8301 41.5863 34.2032L61.7527 14.576ZM33.7792 23.1633L46.0748 23.1641H47.4502L36.1985 34.1134H33.7792C30.4502 34.1134 27.8829 31.6662 27.8829 28.6369C27.8829 25.6068 30.4502 23.1639 33.7792 23.1639V23.1633ZM37.805 37.8865H40.2357C43.5639 37.8865 46.1164 40.3294 46.1164 43.3596C46.1164 46.3897 43.5637 48.8361 40.2357 48.8361L27.9401 48.8369H26.5534L37.805 37.8865Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ZeroInvestmentIcon;
