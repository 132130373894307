import Cookie from "js-cookie"
import * as S from "./styles"

const CookieModal = () => {
  function handleAcceptCookieTerms() {
    Cookie.set("vivo-cookie-auth", "true", {
      path: "/",
      expires: 30,
    })

    window.location.reload()
  }

  return (
    <S.CookieModalOverlay>
      <S.CookieContainer>
        <S.CookieWrapper>
          <p>
            Usamos cookies para garantir que você obtenha a melhor experiência em
            nosso site. Ao clicar em "Aceitar", você concorda que podemos usar esses
            cookies. Clique em{" "}
            <a
              target="__blank"
              href="/normas-de-privacidade"
            >
              políticas de privacidade
            </a>{" "}
            para consultar mais informações.
          </p>

          <S.AcceptCookiesButton onClick={handleAcceptCookieTerms} type="button">
            Aceitar
          </S.AcceptCookiesButton>
        </S.CookieWrapper>
      </S.CookieContainer>
    </S.CookieModalOverlay>
  )
}

export default CookieModal
