import styled, { keyframes } from "styled-components"

const cookieModalOpening = keyframes`
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%)
  }
`

export const CookieModalOverlay = styled.main`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
`

export const CookieContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  background-color: var(--gray5,#e5e5e5);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${cookieModalOpening} 0.4s linear;
`

export const CookieWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-right: 5rem;
  padding-left: 5rem;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  gap: 1.875rem;

  p {
    max-width: 60.3125rem;
    font-size: 1rem;

    a {
      color: #000;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1.875rem;
  }
`

export const AcceptCookiesButton = styled.button`
  background-color: var(--primary, #000);
  color: #fff;
  height: 2.8125rem;
  font-size: 1rem;
  padding: 0.625rem 0.875rem;
  box-sizing: border-box;
  border-radius: 0.625rem;
  transition: 0.2s ease-in-out;
  max-width: 11rem;
  width: 100%;

  &:hover {
    background-color: var(--secondary-salmon, #000);
  }

  @media (max-width: 768px) {
    align-self: self-start;
  }
`
