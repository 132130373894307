import styled from "styled-components";

export const About = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2.5rem 6rem 2.5rem;
  gap: 2rem;

  @media (max-width: 768px) {
    padding: 1.5rem;
  }

  h2 {
    font-family: "SourceSans3-Bold";
    color: var(--black2, #1d1d1d);
    font-size: 2rem;
    line-height: 2.2rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }
`;

export const AboutImg = styled.img`
  width: 100%;
`;

export const AboutRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 59.25rem;
  gap: 2rem;

  .About {
    height: 0;
    width: 0;
  }

  img {
    width: 100%;
    max-width: 30rem;
  }
`;

export const AboutText = styled.div`
  font-family: "SourceSans3-Regular";
  max-width: 39rem;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  text-wrap: balance;

  strong {
    font-family: "SourceSans3-Bold";
    color: var(--primary, #000);
  }

  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
  }
`;

export const AboutPlaceholder = styled.div`
  width: 268px;
  height: 263px;
  background: var(--gray3, #1d1d1d);
`;

export const Divider = styled.div`
  opacity: 0.5;
  border-right: 1px solid var(--black2, #fff);
  content: " ";
  display: block;
  left: 0;
  top: 20%;
  height: 5.5rem;
  margin-right: 0.625rem;

  @media (max-width: 767px) {
    width: 80%;
    height: 100%;
    left: 10%;
    top: 0;
    border-right: 0 !important;
    border-bottom: 1px solid var(--black2, #fff);
    margin-bottom: 0.425rem;
    margin-top: -0.3rem;
  }
`;
