import React from 'react';

interface ArrowMenuIconProps {
  color?: string;
}

const ArrowMenuIcon: React.FC<ArrowMenuIconProps> = ({ color = 'none' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={color}
      stroke={color}
    >
      <path
        d="M6.55912 13.3334C6.40254 13.3334 6.25327 13.2636 6.14791 13.1437C5.93864 12.9009 5.95328 12.5306 6.1801 12.3059L10.6142 7.96078L6.18303 3.69909C5.95474 3.47599 5.93864 3.10567 6.14644 2.86133C6.34693 2.62457 6.69375 2.60028 6.92204 2.80821C6.92643 2.81276 6.93082 2.81579 6.93521 2.82035L11.8171 7.51761C11.9341 7.62992 12 7.78776 12 7.95319C12.0014 8.11862 11.9371 8.27798 11.8214 8.3918L6.93813 13.1756C6.83625 13.2771 6.70039 13.3337 6.55912 13.3334Z"
        fill="#86888C"
      />
    </svg>
  )
}

export default ArrowMenuIcon
