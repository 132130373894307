import styled from "styled-components"

interface IFaqContentWrapper {
  $openFaq: boolean
}

interface IQuestionTrigger {
  $faqOpenned: boolean
}

export const FaqItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1rem;
  box-sizing: border-box;
  cursor: pointer;

  &:nth-child(2n + 1) {
    border-top: 1px solid var(--gray5,#e5e5e5);
    border-bottom: 1px solid var(--gray5,#e5e5e5);
  }

  &:last-child {
    border-bottom: 1px solid var(--gray5,#e5e5e5);
  }

  @media (max-width: 768px) {
    padding: 0.375rem 0.5rem;
  }
`

export const QuestionTitle = styled.button<IQuestionTrigger>`
  all: unset;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 1.875rem;
  color: var(--primary, #000);
  font-family: "SourceSans3-Medium";

  h3 {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;
    max-width: fit-content;
  }

  svg {
    transition: all 0.3s ease-in-out;
    transform: ${(props) => (props.$faqOpenned ? "rotate(180deg)" : "rotate(0deg)")};
    min-width: 20px;
    min-height: 20px;
  }

  @media (max-width: 768px) {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
`

export const FaqContentWrapper = styled.div<IFaqContentWrapper>`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 45.625rem;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  margin-top: ${(props) => (props.$openFaq ? "1.5rem;" : "0")};
  max-height: ${(props) => (props.$openFaq ? "62.5rem" : "0")};
  cursor: default;

  @media (max-width: 768px) {
    gap: 1rem;
  }
`

export const FaqAnswer = styled.p`
  width: 100%;
  color: var(--black2, #0a0a0a);
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;

  @media (max-width: 768px) {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
`

export const RateFaq = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.9375rem;

  p {
    font-family: "VototantimSans-Regular";
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.25rem;
  }
`
export const AvaliationButtons = styled.span`
  display: flex;

  button {
    display: flex;
    align-items: center;
    color: #000;
    font-family: "VototantimSans-Regular";
    font-size: 0.75rem;
    line-height: 1.25rem;
    text-decoration: underline;
    gap: 0.3125rem;

    &.highlight {
      color: var(--secondary-orange, #000);
      font-weight: bold;

      svg {
        path {
          fill: var(--secondary-orange, #000);
        }
      }
    }

    &:hover {
      color: var(--secondary-orange, #000);
    }

    &:disabled {
      opacity: 0.6;
      cursor: default;
    }
  }
`
