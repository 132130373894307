import * as S from "./styles";
import EconomyIcon from "../../../assets/icons/features/EconomyIcon";
import ZeroInvestmentIcon from "../../../assets/icons/features/ZeroInvestmentIcon";
import SustentabilityIcon from "../../../assets/icons/features/SustentabilityIcon";
import MonitorIcon from "../../../assets/icons/features/MonitorIcon";

const AdvantageComponent = () => {
  return (
    <S.Advantage>
      <S.SideImages >
        <S.SideImageLeft />
        <S.SideImageRight />
      </S.SideImages>
      <S.MarketFloatRow>
        <S.MarketCol>
          <S.MarketFeatureHead>+15.000</S.MarketFeatureHead>
          <S.MarketFeatureText>
            <strong>empresas no Brasil</strong>
            <br />
            já estão economizando em sua conta de luz
          </S.MarketFeatureText>
        </S.MarketCol>
        <S.MarketCol>
          <S.MarketFeatureHead>+90%</S.MarketFeatureHead>
          <S.MarketFeatureText>
            <strong>do consumo industrial</strong>
            <br />
            no país já está no Mercado Livre de Energia
          </S.MarketFeatureText>
        </S.MarketCol>
        <S.MarketCol>
          <S.MarketFeatureHead>+37%</S.MarketFeatureHead>
          <S.MarketFeatureText>
            <strong>de toda energia consumida</strong>
            <br />
            no Brasil já é do Mercado Livre de Energia
          </S.MarketFeatureText>
        </S.MarketCol>
      </S.MarketFloatRow>

      <S.AdvantageRow>
        <h2>Conheça as vantagens</h2>
      </S.AdvantageRow>

      <S.AdvantageRow>
        <S.AdvantageText>
          Com a abertura do <strong>Mercado Livre de Energia no Brasil</strong>, as pequenas e médias empresas do Grupo A
          (consumo energético de média e alta tensão) agora podem adquirir energia de qualquer fornecedor.
          Isso garante mais <strong>liberdade, poder de escolha, economia e o mais importante, a oportunidade de
            optar por fontes de energia 100% renováveis</strong>, fortalecendo assim seu compromisso com a sustentabilidade.
        </S.AdvantageText>
      </S.AdvantageRow>

      <S.FeatureFloatRow>
        <S.FeatureCol>
          <EconomyIcon />
          <S.CardFeatureHead>
            Economia garantida na conta de luz
          </S.CardFeatureHead>
          <S.CardFeatureText>
            Até 30% de desconto todo mês na conta de luz da sua empresa, sem
            diminuir o consumo atual.
          </S.CardFeatureText>
        </S.FeatureCol>

        <S.FeatureCol>
          <ZeroInvestmentIcon />
          <S.CardFeatureHead>
            Zero investimento e sem burocracia
          </S.CardFeatureHead>
          <S.CardFeatureText>
            Redução de custo sem nenhum investimento e ainda cuidamos de todo o processo para sua empresa!
          </S.CardFeatureText>
        </S.FeatureCol>

        <S.FeatureCol>
          <SustentabilityIcon />
          <S.CardFeatureHead>
            Sustentabilidade para sua marca
          </S.CardFeatureHead>
          <S.CardFeatureText>
            Ganhe um selo comprovando o consumo de energia 100% limpa e fortaleça sua marca.
          </S.CardFeatureText>
        </S.FeatureCol>

        <S.FeatureCol>
          <MonitorIcon />
          <S.CardFeatureHead>
            Monitoramento do seu consumo
          </S.CardFeatureHead>
          <S.CardFeatureText>
            Acompanhe seu consumo de forma online e ganhe previsibilidade e controle de gastos.
          </S.CardFeatureText>
        </S.FeatureCol>
      </S.FeatureFloatRow>
      <span className="AdvantageDesk" />
    </S.Advantage>
  );
};

export default AdvantageComponent;
