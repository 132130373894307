const MonitorIcon = () => {
  return (
    <svg 
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 89 74"
      width="89"
      height="74"
    >
      <g>
        <path
          fillRule="evenodd"
          fill="currentColor"
          stroke="#b0049c"
          strokeWidth="2.5"
          d="m12.2 60c-1.9 0-3.5-0.6-4.9-2-1.3-1.3-2-2.9-2-4.8v-44.4c0-1.9 0.7-3.5 2-4.8 1.4-1.4 3-2 4.9-2h64.6c1.9 0 3.5 0.6 4.8 2 1.4 1.3 2 2.9 2 4.8v44.4c0 1.9-0.6 3.5-2 4.8-1.3 1.4-2.9 2-4.8 2zm-10.9 12.2v-5.6h86.4v5.6zm9.6-17.7h67.1v-47h-67.1z"
        />
      </g>

      <g>
        <path
          fillRule="evenodd"
          fill="currentColor"
          stroke="#b0049c"
          d="m36.7 49l1.7-12.4 0.1-0.5h-8.9l16-23.1h2.9l-1.8 14.2-0.1 0.6h10.6l-17.6 21.2zm3.6-6.6l-0.2 1.8 1.1-1.4 9.6-11.5 0.6-0.8h-7.9l1.3-9.9-0.9-0.4-8.6 12.4-0.5 0.8h6.8z"
        />
      </g>
    </svg>
  );
};

export default MonitorIcon;
