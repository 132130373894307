import styled from "styled-components";

export const Terms = styled.section`
    background-color: var(--gray5, #faf8fa);
    display: flex;
    flex-direction: column;
    padding: 6rem 8rem;
    gap: 2rem;

    @media (max-width: 768px) {
        padding: 1.5rem;
    }

    h2 {
        font-family: "SourceSans3-Bold";
        color: var(--black2, #1d1d1d);
        font-size: 2rem;
        line-height: 2.2rem;
        margin-bottom: 0.5rem;
        text-align: left;
    }

    p {
        font-family: "SourceSans3-Regular";
        color: var(--black2, #1d1d1d);
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: left;
        word-wrap: break-word;
    }
`;