import GoTopButton from "../GoTopButton";
import { Terms } from "./styles";

const TermsOfPrivacy = () => {
    return (
        <>
            <Terms>
                <h2>Norma de Privacidade</h2>
                <p>
                    <strong>WEBSITE GUD ENERGIA</strong>
                    <br></br>Esta Norma de Privacidade tem como finalidade apresentar a você (“Você”,
                    “Usuário”), que acessa o website da GUD no endereço
                    www.gudenergia.com.br (“Serviço” ou “Website”), as informações essenciais
                    sobre como seus dados pessoais são coletados e tratados uma vez que você
                    acesse o website. (“Empresa”, “GUD”, “nós”).
                    A presente Norma de Privacidade está disponível para leitura e consulta pelo
                    Usuário a qualquer tempo por meio do endereço www.gudenergia.com.br.
                </p>
                <p>
                    <strong>1. DADOS COLETADOS E FORMA DE COLETA</strong>
                    <br></br>Dados fornecidos pelo Usuário. Ao utilizar nosso Serviço para certas finalidades,
                    você pode nos fornecer alguns dados pessoais por meio de formulários de
                    contato, inscrição para recebimento de notícias, entre outros. Todos os Dados
                    Pessoais fornecidos por você durante seu uso do Serviço serão armazenados por
                    nós e tratados conforme esta Norma, o que inclui nome, endereço de e-mail,
                    telefone, informações de contato, empresa ou instituição que representa ou em
                    que trabalha, área de atuação e outras informações que você mesmo insira em
                    campos abertos do Website (campo “Comentários” ou “Mensagem” nos
                    formulários de contato, por exemplo).
                    <br></br>Dados coletados automaticamente pelo Website. Nosso Serviço coleta
                    automaticamente algumas informações técnicas, incluindo, mas não limitadas a
                    tipo de dispositivo usado para acessar o Serviço, seu IP de conexão (com data de
                    hora de acesso), sua localização geográfica aproximada, o número de
                    identificação único do seu dispositivo (como MAC Address ou IMEI), informações
                    sobre o uso do Serviço, o software ou navegador utilizado para acessar o Serviço,
                    o sistema operacional e sua versão do dispositivo utilizado, entre outros.
                    <br></br>Cookies e outras tecnologias de rastreamento. A GUD também poderá utilizar
                    tecnologia padrão para coletar informações dos Usuários, tais como cookies,
                    web-beacons, device fingerprinting, entre outros, de modo a garantir a execução
                    das finalidades necessárias para o funcionamento do site, entender como você
                    utiliza o Serviço, quais páginas acessa com mais frequência, quais conteúdos são
                    mais interessantes, melhorar sua experiência de navegação e performance do
                    site, bem como targeting/advertising, conforme item 4 desta Norma.
                </p>
                <p>
                    <strong>2. FINALIDADES DO TRATAMENTO</strong>
                    <br></br><strong>2.1. Dados necessários para o fornecimento do Serviço</strong>
                    <br></br>Os dados pessoais coletados pelo serviço serão utilizados primariamente para o
                    fornecimento do Serviço em si. Nós utilizamos esses dados apenas para as
                    finalidades descritas nesta Norma. Como não conseguimos fornecer tais
                    funcionalidades ou serviços sem os dados solicitados, sua coleta e tratamento
                    são fundadas especialmente em tal necessidade. Assim, nós usaremos, por
                    exemplo:
                    <br></br>· Dados técnicos básicos (como endereço IP, navegador e tipo de dispositivo)
                    para possibilitar seu acesso ao Website;
                    <br></br>· Dados do formulário de contato específico para possibilitar o agendamento
                    de visitas às nossas Usinas;
                    <br></br>· Dados do formulário de contato específico para fazer contato e negociar uma possível parceria com potenciais fornecedores e geradores de energia que façam tal cadastro;
                </p>
                <p>
                    <strong>2.2. Dados necessários para comunicação e divulgação</strong>
                    <br></br>Alguns dados são coletados e utilizados para possibilitar a comunicação entre
                    você e a GUD pelos canais disponíveis no Website e para o envio, por exemplo,
                    de newsletters e notícias sobre nossos produtos e serviços. Você pode cancelar o
                    recebimento de mensagens institucionais sobre nossos produtos e serviços, o
                    recebimento do newsletter e mesmo outros tipos de comunicação a qualquer
                    momento, por meio de link disponível na própria mensagem ou entrando em
                    contato conosco. A coleta e tratamento desses dados é baseada, especialmente,
                    no cumprimento de tais interesses legítimos, da GUD e do próprio usuário,
                    quando este manifesta seu interesse em tais comunicações ao utilizar o Serviço e
                    suas funcionalidades.
                </p>
                <p>
                    <strong>2.3. Dados coletados para cumprimento de obrigações legais</strong>
                    <br></br>Nós somos obrigados por lei a conservar alguns dados básicos de registro de
                    acesso ao nosso Serviço, incluindo, mas não limitado, a endereço IP da conexão
                    de origem, com data e hora. Tais dados são conservados pelo período mínimo de
                    12 (doze) meses. Eventualmente, outros dados podem ser armazenados também
                    para cumprimento de obrigações legais.
                </p>
                <p>
                    <strong>2.4. Dados coletados para melhoria do Serviço e suporte técnico</strong>
                    <br></br>Podemos utilizar, por exemplo, informações técnicas coletadas do seu dispositivo
                    para facilitar o processo de suporte e fornecimento de assistência técnica em
                    caso de bug ou mal funcionamento do Serviço. Nós utilizamos também os dados
                    coletados, principalmente, mas não exclusivamente, os de utilização do Serviço e
                    informações técnicas do seu dispositivo para melhorar o Serviço em si,
                    adicionando funcionalidades ou melhorando outros aspectos dela e sua
                    navegabilidade. Tal uso dos dados é baseado em um interesse legítimo da GUD
                    em oferecer a você a melhor experiência possível no Website.
                </p>
                <p>
                    <strong>2.5. Finalidades secundárias</strong>
                    <br></br>A Empresa pode utilizar os dados coletados também para finalidades secundárias
                    que não sejam conflitantes ou excessivas em relação às finalidades listadas
                    acima, sempre em conformidade com a legislação brasileira, com base em
                    interesse legítimo seu, exceto nos casos em que prevaleçam os direitos e
                    garantias legais do Usuário.
                </p>
                <p>
                    <strong>3. CONSERVAÇÃO DOS DADOS</strong>
                    <br></br><strong>3.1. Fornecimento do Serviço e funcionalidades</strong>
                    <br></br>Os dados coletados e utilizados para possibilitar o uso do Serviço e de suas
                    funcionalidades, incluindo aqueles voluntariamente fornecidos pelo Usuário,
                    serão mantidos pelo período máximo de 2 (dois) anos, sendo que, sem sua
                    conservação, não é possível fornecer as correspondentes funcionalidades do
                    Serviço. Isso aplica-se especialmente aos cadastros, submissões, pedidos de
                    agendamento e contatos com a GUD feitos pelo Usuário no Website. Caso o
                    usuário não acesse o website durante esse período de 2 (dois) anos acima
                    informado, os dados serão automaticamente removidos.
                </p>
                <p>
                    <strong>3.2. Melhoria do Serviço e suporte técnico</strong>
                    <br></br>Os dados coletados para permitir a melhoria do Serviço e para atendimento do
                    suporte técnico serão mantidos pelo tempo necessário para implementação ou
                    prestação do auxílio específico solicitado pelo Usuário, podendo ser excluídos
                    definitivamente ou anonimizados para compor banco de dados agregados com
                    finalidades estatísticas.
                </p>
                <p>
                    <strong>3.3. Obrigações legais</strong>
                    <br></br>Os dados coletados e mantidos com a finalidade de cumprimento de obrigações
                    legais pela Empresa serão conservados pelo prazo indicado na Lei.
                </p>
                <p>
                    <strong>3.4. Defesa de direitos</strong>
                    <br></br>Eventualmente certos dados relevantes, sempre considerando o mínimo
                    necessário, podem ser armazenados além do prazo descrito acima, com a
                    finalidade de defesa de direitos da GUD por vias judiciais ou extrajudiciais.
                </p>
                <p>
                    <strong>4. COOKIES E TECNOLOGIAS DE RASTREAMENTO</strong>
                    <br></br>Cookies são arquivos ou informações que podem ser armazenadas em seus
                    dispositivos, quando você acessa o Website. Geralmente, um cookie contém o
                    nome do site que o originou, seu tempo de vida e um valor, que é gerado
                    aleatoriamente.
                    <br></br>A GUD utiliza cookies para facilitar o uso e melhor adaptar o Website aos seus
                    interesses e necessidades, bem como para compilarmos informações sobre a
                    utilização de nossos sites e serviços, auxiliando a melhorar suas estruturas e seus
                    conteúdos. Os cookies também podem ser utilizados para acelerar suas
                    atividades e experiências futuras no Website.
                    <br></br>Após o Usuário consentir para a utilização de cookies, quando do uso do Serviço,
                    A GUD armazenará um cookie em seu dispositivo para lembrar disso na próxima
                    sessão.
                    <br></br>A qualquer momento, o Usuário poderá revogar seu consentimento quanto aos
                    cookies, pelo que deverá apagar os cookies do Serviço utilizando as
                    configurações de seu navegador de preferência. Para mais informações sobre
                    como proceder em relação à gestão dos cookies nos navegadores:
                    <br></br>· Internet Explorer: https://support.microsoft.com/pt-br/help/17442/windowsinternet-explorer-delete-manage-cookies
                    <br></br>· Mozilla Firefox: https://support.mozilla.org/pt-BR/kb/ative-e-desative-oscookies-que-os-sites-usam
                    <br></br>· Google Chrome: https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-BR
                    <br></br>· Safari: https://support.apple.com/pt-br/guide/safari/sfri11471/mac
                    <br></br>· Edge: https://support.microsoft.com/pt-br/microsoft-edge/excluir-cookies-nomicrosoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09#:~:text=No%20Edge%2C%20selecione%20Configura%C3%A7%C3%B5es%20e%20mais%20%3E%20Configura%C3%A7%C3%B5es%20%3E%20Cookies%20e,cujos%20cookies%20voc%C3%AA%20deseja%20excluir.
                    <br></br>Por fim, lembramos que, caso o Usuário não aceite alguns cookies do Website,
                    certos serviços poderão não funcionar de maneira ideal.
                </p>
                <p>
                    <strong>5. SEGURANÇA E CONFIDENCIALIDADE</strong>
                    <br></br><strong>5.1. Segurança lógica e organizacional</strong>
                    <br></br>A GUD considera todas as informações coletadas por meio do Serviço
                    confidenciais, garantindo que estas serão tratadas e armazenadas conforme as
                    determinações desta Norma e com a adoção das adequadas medidas de
                    segurança lógicas e organizacionais, conforme o grau de sensibilidade dos dados
                    e os riscos inerentes à atividade. Tais medidas seguirão o padrão de melhores
                    práticas da indústria, utilizando-se, por exemplo, de criptografia, conexões
                    seguras, autenticação multifatorial, entre outros. A GUD declara que possui
                    todas as políticas internas de segurança adequadas e razoáveis para garantir o
                    correto tratamento dos dados.
                </p>
                <p>
                    <strong>5.2. Restrição de acesso</strong>
                    <br></br>A GUD aplicará, conforme cabível e definido no Decreto nº 8.771/2016, os
                    controles adequados para restrição de acesso aos dados tratados, incluindo o
                    estabelecimento de responsabilidades, políticas de acesso, mecanismos de
                    autenticação de usuário para acesso aos dados, criação de inventário e registros
                    de tratamento, encriptação em descanso, entre outros.
                </p>
                <p>
                    <strong>6. COMUNICAÇÃO E USO COMPARTILHADO</strong>
                    <br></br><strong>6.1. Transmissão a operadores do tratamento</strong>
                    <br></br>A GUD poderá transmitir os dados coletados por meio do Serviço a prestadores
                    de serviço, subcontratados ou outros fornecedores que atuarem, sob direção da
                    Auren, no tratamento de tais dados pessoais na condição de operadores do
                    tratamento. Tal eventual transmissão é feita em conformidade com o disposto na
                    Lei Geral de Proteção de Dados, adotando os Operadores todas as precauções
                    exigidas em Lei para garantia da segurança e da confidencialidade dos dados
                    comunicados.
                </p>
                <p>
                    <strong>6.2. Comunicação a terceiros</strong>
                    <br></br>A GUD poderá compartilhar os dados coletados por meio do Serviço com
                    terceiros nas situações descritas abaixo:
                    <br></br>· Para proteção dos interesses da GUD em qualquer tipo de conflito, inclusive demandas judiciais;
                    <br></br>· No caso de transações e alterações societárias envolvendo A GUD, hipótese em que a transferência das informações será necessária para a continuidade dos serviços e entrega dos produtos; ou
                    <br></br>· Mediante ordem judicial ou pelo requerimento de autoridades administrativas que detenham competência legal para sua requisição.
                </p>
                <p>
                    <strong>6.3. Uso compartilhado</strong>
                    <br></br>A GUD pode vir a realizar o uso compartilhado de dados com entes públicos, por
                    solicitação destes, para projetos de interesse da GUD e/ou de interesse público,
                    comprometendo-se a Empresa a prestar aos Usuários todas as informações
                    cabíveis, como disposto na LGPD, sempre que tal uso compartilhado ocorrer.
                </p>
                <p>
                    <strong>7. DIREITOS DO TITULAR DOS DADOS</strong>
                    <br></br><strong>7.1. Direitos do Titular</strong>
                    <br></br>O titular dos dados pessoais coletados e tratados possui os seguintes direitos, em
                    conformidade com a Lei Geral de Proteção de Dados (Lei nº 13.709/2018):
                    <br></br>· Confirmação da existência do tratamento;
                    <br></br>· Acesso aos dados;
                    <br></br>· Correção de dados incompletos, inexatos ou desatualizados;
                    <br></br>· Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na Lei;
                    <br></br>· Portabilidade dos dados a outro fornecedor de serviço ou produto, resguardados os segredos comerciais e industriais da GUD;
                    <br></br>· Eliminação dos dados tratados com o consentimento do titular, exceto nas hipóteses de guarda legal e outras dispostas em Lei;
                    <br></br>· Todas as informações pertinentes, como exigido por Lei e disponibilizadas
                    neste documento e em outros locais acessíveis ao Usuário;
                    <br></br>· Revogação do consentimento, quando o tratamento se basear nele.
                </p>
                <p>
                    <strong>7.2. Forma e condições de exercício</strong>
                    <br></br>Os direitos mencionados acima serão garantidos e poderão ser exercidos nos
                    exatos termos descritos na Lei Geral de Proteção de Dados.
                </p>
                <p>
                    <strong>7.3. Exclusão de dados</strong>
                    <br></br>A exclusão dos dados armazenados pela GUD poderá ser requisitada, nas
                    condições estabelecidas pela Lei Geral de Proteção de Dados, por meio do e-mail
                    [dpo@aurenenergia.com.br]. A Empresa declara-se comprometida a empreender
                    os melhores esforços para atender a todos os pedidos de exclusão, quando
                    cabíveis, o mais breve possível. Tal exclusão, quando abranger dados necessários
                    para o funcionamento do Serviço, acarretará também o encerramento da
                    prestação de tais serviços, não podendo o Usuário utilizar o Serviço. Mesmo
                    nesse caso, A GUD Energia poderá manter os dados pessoais armazenados, sem
                    sujeitá-los a tratamento ativo, de modo a cumprir com suas obrigações legais de
                    guarda de dados ou para defesa judicial de seus interesses.
                </p>
                <p>
                    <strong>8. RESPONSÁVEIS PELO TRATAMENTO</strong>
                    <br></br>O tratamento dos dados coletados é realizado pela GUD, ou por terceiros sob
                    orientação da GUD, sendo esta responsável pela legalidade do projeto como um
                    todo. Toda e qualquer solicitação, reclamação, pedido de informação ou exercício
                    de direitos do titular pode ser realizada por mensagem escrita para o endereço
                    [dpo@aurenenergia.com.br], contendo, no mínimo, as seguintes informações e
                    documentos:
                    <br></br>· Nome completo do requerente;
                    <br></br>· Números de RG e CPF do requerente;
                    <br></br>· Endereço de e-mail utilizado para cadastro no Website;
                    <br></br>· Telefone utilizado para cadastro no Website;
                    <br></br>· Descrição da solicitação.
                    <br></br>Eventualmente, outras informações ou documentos podem ser solicitados para o
                    atendimento da requisição, caso haja dúvida sobre a veracidade das informações
                    prestadas ou sobre a identidade do solicitante.
                </p>
                <p>
                    <strong>9. DISPOSIÇÕES GERAIS</strong>
                    <br></br><strong>9.1. Lei aplicável e foro competente</strong>
                    <br></br>A presente Norma é regida pela Lei brasileira, sendo exclusivamente competente
                    para conhecer de quaisquer conflitos originados desta Norma o foro da Comarca
                    de São Paulo/SP, em prejuízo de qualquer outro, por mais privilegiado que seja.
                </p>
                <p>
                    <strong>9.2. Modificações</strong>
                    <br></br>Nosso serviço pode mudar de tempos em tempos. Por isso, podemos modificar
                    unilateralmente esta Norma de Privacidade a qualquer tempo. Sempre que
                    houver uma mudança relevante dela, você será notificado através das
                    informações de contato disponibilizadas por você ou por um aviso no Website. Na
                    notificação, você terá acesso ao novo texto da Norma de Privacidade, bem como
                    um resumo das alterações efetuadas. Após tal notificação, ao continuar utilizando
                    o Serviço, você concorda automaticamente com os novos termos da Norma. Caso
                    não concorde com as alterações e queira cancelar e apagar sua conta, pedimos
                    que envie um e-mail para dpo@aurenenergia.com.br. A versão atualizada desta Norma estará sempre disponível no endereço: www.gudenergia.com.br
                </p>
                <p>
                    <strong>10. ENTRE EM CONTATO</strong>
                    <br></br>Caso você tenha dúvidas ou solicitações sobre esta Norma de Privacidade, sobre
                    nossas práticas, ou sobre nosso serviço, por favor entre em contato:
                    dpo@aurenenergia.com.br
                </p>
            </Terms>
            <GoTopButton />
        </>
    );
};

export default TermsOfPrivacy;
