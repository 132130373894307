import { useCallback, useMemo } from "react";
import { questionsContent } from "../../../mocks/questionsContent";
import FaqContent from "../../FaqContent";
import * as S from "./styles";

const HomeFaq = () => {
  function randomNumberGenerator() {
    const randomNumber =
      Math.floor(Math.random() * questionsContent.length - 1) + 1;

    return randomNumber;
  }

  const faqShuffle = useCallback(() => {
    const mixedArr = [];
    const numbersGenerated: number[] = [];

    for (let i = 0; i <= questionsContent.length - 1; i++) {
      let randomNumber = randomNumberGenerator();

      if (!numbersGenerated.includes(randomNumber)) {
        mixedArr.push(questionsContent[randomNumber]);
      } else {
        while (numbersGenerated.includes(randomNumber)) {
          randomNumber = randomNumberGenerator();

          const checkIfNumberAlreadyExistsAgain =
            numbersGenerated.includes(randomNumber);

          if (!checkIfNumberAlreadyExistsAgain) {
            mixedArr.push(questionsContent[randomNumber]);
          }
        }
      }

      numbersGenerated.push(randomNumber);
    }

    return mixedArr;
  }, []);

  const mixedQuestions = useMemo(faqShuffle, [faqShuffle]);

  return (
    <S.FaqContainer>
      <S.FaqWrapper>
        <span className="faq" />

        <S.TitleText>Perguntas Frequentes</S.TitleText>

        <S.Faqs>
          {mixedQuestions.map(({ question, answer, apiInfo }) => {
            return (
              <FaqContent
                key={question}
                question={question}
                answer={answer}
                apiInfo={apiInfo}
              />
            );
          })}
        </S.Faqs>
      </S.FaqWrapper>
    </S.FaqContainer>
  );
};

export default HomeFaq;
