const AdvantageIcon = () => {
  return (
    <svg
      width="40"
      height="45"
      viewBox="0 0 40 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.9664 0C1.76505 0 0 1.76855 0 3.93011V35.371C0 37.5522 1.76505 39.3011 3.9664 39.3011H19.4552C18.7413 38.0828 18.2455 36.7466 18.0075 35.371H3.9664V3.93011H17.8488V13.7554H27.7648V21.7728C28.4193 21.6746 29.0936 21.6156 29.748 21.6156C30.4223 21.6156 31.0768 21.6746 31.7312 21.7728V11.7903L19.832 0M7.93281 19.6506V23.5807H23.7984V19.6506M7.93281 27.5108V31.4409H17.8488V27.5108H7.93281Z"
        fill="currentColor"
      />
      <path
        d="M29.0518 32.4316V33.8528H34.9325L32.1798 37.132V35.7107H26.2991L29.0518 32.4316ZM32.1798 24.5636L21.2316 37.5686H29.0518V45L40 31.995H32.1798V24.5636Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AdvantageIcon;
