import { useEffect, useState } from "react"
import ArrowTopIcon from "../../assets/icons/ArrowTopIcon"
import { ArrowTopButton } from "./styles"

const GoTopButton = () => {
  const [showButton, setShowButton] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 200) {
        return setShowButton(true)
      }

      setShowButton(false)
    })
  }, [])

  function handleGoTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <ArrowTopButton $showButton={showButton}>
      <button onClick={handleGoTop} type="button">
        <ArrowTopIcon />
      </button>
    </ArrowTopButton>
  )
}

export default GoTopButton
