import { useState } from "react"
import ArrowListIcon from "../../assets/icons/ArrowListIcon"
/* import ThumbDown from "../../assets/icons/ThumbDown"
import ThumbUp from "../../assets/icons/ThumbUp"
import axios from "axios" */
import * as S from "./styles"

interface IFaqContent {
  question: string
  answer: string
  apiInfo: string
}

const FaqContent = ({ question, answer /* , apiInfo  */ }: IFaqContent) => {
  const [openFaq, setOpenFaq] = useState(false)
  /* 
  const [loadingAvaliation, setLoadingAvaliation] = useState(false)
  const [highlightButton, setHighlightButton] = useState("")

  async function handleSendUtility(utility: string, endpointInfo: string) {
    setLoadingAvaliation(true)

    try {
      await axios.post("https://e7d9-177-9-118-184.ngrok-free.app/faq", {
        [endpointInfo]: utility,
        lp: "vivo",
      })

      setHighlightButton(utility)
    } catch (e) {
      console.error("Ocorreu um erro ao enviar avaliação. Tente novamente.")
    } finally {
      setLoadingAvaliation(false)
    }
  } */

  return (
    <S.FaqItem onClick={() => setOpenFaq(!openFaq)}>
      <S.QuestionTitle $faqOpenned={openFaq} type="button">
        <h3>{question}</h3>
        <ArrowListIcon />
      </S.QuestionTitle>

      <S.FaqContentWrapper onClick={(e) => e.stopPropagation()} $openFaq={openFaq}>
        <S.FaqAnswer dangerouslySetInnerHTML={{ __html: answer }} />

        {/*         <S.RateFaq>
          <p>Isso foi útil?</p>

          <S.AvaliationButtons>
            <button
              className={highlightButton === "sim" ? "highlight" : ""}
              disabled={loadingAvaliation}
              onClick={() => handleSendUtility("sim", apiInfo)}
              type="button"
            >
              sim <ThumbUp />
            </button>
            <button
              className={highlightButton === "não" ? "highlight" : ""}
              disabled={loadingAvaliation}
              onClick={() => handleSendUtility("não", apiInfo)}
              type="button"
            >
              não <ThumbDown />
            </button>
          </S.AvaliationButtons>
        </S.RateFaq> */}
      </S.FaqContentWrapper>
    </S.FaqItem>
  )
}

export default FaqContent
