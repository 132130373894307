//@ts-ignore
import { Dispatch, SetStateAction } from "react"

export function formatNumberToBRL(
  event: React.ChangeEvent<HTMLInputElement>,
  valueState: Dispatch<SetStateAction<string>>
): void {
  const userInput: string = event.target.value.replace(/[^0-9]/g, "")

  if (userInput === "") {
    valueState("0")
  } else {
    const userInputAsNumber: number = parseInt(userInput, 10) / 100

    const formattedNumber: string = `${userInputAsNumber
      .toFixed(2)
      .replace(".", ",")
      .replace(/(\d)(?=(\d{3})+\,)/g, "$1.")}` /* eslint-disable-line */

    valueState(formattedNumber)
  }
}
